import { Box, Link, Toolbar, Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import environment from '../providers/environment'
import { MGMT_API } from '../services/Api'

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      Copyright &copy; &#160;
      <Link color='inherit' href='https://writesystem.eu/'>
        WriteSystem
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  appBar: {
    //borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      minHeight: '80px',
      flexDirection: 'row',
    },
    backgroundColor: 'white',
    paddingTop: '10px',
  },
  homeLinkBtn: {},
  link: {
    margin: theme.spacing(1, 1.5),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
      lineHeight: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
  footerRightText: {
    width: '40%',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      width: 'inherit',
    },
  },
  footerRightLogo: {
    width: '40%',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '0',
      justifyContent: 'center',
    },
  },
  alignRight: {
    textAlign: 'right',
  },
  logo: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  toolbarLogo: {
    width: '50%',
    height: '100px',
    maxWidth: '250px',
    cursor: 'pointer',
  },
  toolbarLogoSyn: {
    width: '100%',
    height: '40px',
    maxWidth: '141px',
    cursor: 'pointer',
    backgroundImage: "url('/assets/img/synedrio-logo.png')",
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    '& span': {
      alignItems: 'center',
    },
    '& div:not(:last-child)': {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(5),
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(1),
      },
    },
  },
}))

export const Footer: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  const [tenantConf, setTenantConf] = useState(null as any)

  async function getTenantPublicConfig(): Promise<any> {
    const tenantResponse = await MGMT_API.get(
      `/tenants/${environment.tenant.tenant_name}`,
    )
    return tenantResponse
  }

  useEffect(() => {
    getTenantPublicConfig().then((res: any) => {
      if (res?.status === 200 && res?.data?.public) {
        setTenantConf(res.data.public)
      } else
        console.error(
          'Cannot retrieve tenant public configuration: ' +
            JSON.stringify(res, null, 4),
        )
    })
  }, [])

  return (
    <React.Fragment>
      <footer className={classes.footer}>
        <Toolbar className={classes.toolbar}>
          <Box
            display='flex'
            flexDirection='column'
            className={`${classes.footerRightText}`}
            justifyContent='flex-start'
            alignItems='flex-start'
          >
            <Box mb={2} component='span'>
              <Link href='#' variant='body2'>
                Privacy Policy
              </Link>
            </Box>
            {/* <Box component='span'>
              Realizzato da: &nbsp;
              <Link href='#' variant='body2'>
                Softinstigate Srl
              </Link>
            </Box> */}
            <Box component='span'>{Copyright()}</Box>
          </Box>
          {tenantConf ? (
            <Box
              component='div'
              onClick={() => history.push('/')}
              className={`${classes.toolbarLogo} ${classes.logo}`}
              style={{
                backgroundImage: `url('${tenantConf.assets.logo_url}')`,
              }}
            ></Box>
          ) : (
            <span></span>
          )}

          <Box
            display='flex'
            justifyContent='flex-end'
            className={`${classes.footerRightLogo}`}
            alignContent='end'
          >
            <Box
              component='span'
              onClick={() => history.push('/')}
              className={`${classes.toolbarLogoSyn} ${classes.logo}`}
            ></Box>
          </Box>
        </Toolbar>
      </footer>
    </React.Fragment>
  )
}
