import { Grid, Typography, Box, Button } from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { LiveCtx } from '../../../providers/context/LiveContext'
import { ArchiveService } from '../../../services/Archive/ArchiveService'
import { VodService } from '../../../services/Vod/VodService'
import { MatCarousel } from '../../carousel/MatCarousel'
import VideoCard from '../VideoCard'

const MOBILE_BREAKPOINT = 900 // window.innerWidth (px)

const LastVods: React.FC<any> = (props: any) => {
  const [vods, setVods] = useState({
    smallVods: [],
    bigVods: [],
    allVods: [],
  } as any)

  const [assets, setAssets] = useState(null as any)
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= MOBILE_BREAKPOINT,
  )

  const liveContext = useContext(LiveCtx)
  const { liveActive } = liveContext

  window.addEventListener('resize', () => {
    setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT)
  })

  useEffect(() => {
    VodService.getVods(6).then((res: any) => {
      const arrangedVods = arrangeLayout(res, liveActive)
      setVods({ ...arrangedVods, allVods: res })
    })

    if (assets === null) {
      ArchiveService.getAssets().then((res: any) => {
        setAssets(res)
      })
    }
  }, [liveActive])

  const VOD_POSTER = assets?.default_vod_poster
    ? assets?.default_vod_poster
    : 'https://synedrio-prod.s3.eu-central-1.amazonaws.com/assets/default_vod_poster.jpg'

  return isMobile ? (
    <Fragment>
      {/* Big VOD events */}
      <Box mt={3} mb={3}>
        <Typography
          component='h2'
          variant='h5'
          align='center'
          color='textPrimary'
          gutterBottom
        >
          ULTIME SEDUTE
        </Typography>
      </Box>
      <MatCarousel
        animation={'slide'}
        navButtonsAlwaysVisible={true}
        autoPlay={false}
      >
        {vods.allVods.map((vod: any, index: number) => (
          <VideoCard
            key={index}
            title={vod.title}
            poster={vod.poster || VOD_POSTER}
            description={vod.description}
            date={vod.timeslot.start.$date}
            time={vod.time}
            href={'/vod/' + vod._id.$oid}
            showActions={false}
            isLive={false}
          ></VideoCard>
        ))}
      </MatCarousel>
      <Box mt={3} display='flex' justifyContent='center' alignContent='center'>
        <Button href='/search' color='secondary' variant='contained'>
          Consulta l&apos; archivio
        </Button>
      </Box>
    </Fragment>
  ) : (
    <Fragment>
      {/* Big VOD events */}
      <Box mt={3} mb={3}>
        <Typography
          component='h2'
          variant='h5'
          align='center'
          color='textPrimary'
          gutterBottom
        >
          ULTIME SEDUTE
        </Typography>
      </Box>

      <Grid container spacing={4} style={{ placeContent: 'center' }}>
        {props.compact &&
          vods.allVods.slice(0, 4).map((vod: any, index: number) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={3}
              style={{ alignSelf: 'center' }}
            >
              <VideoCard
                title={vod.title}
                poster={vod.poster || VOD_POSTER}
                description={vod.description}
                date={vod.timeslot.start.$date}
                time={vod.time}
                href={'/vod/' + vod._id.$oid}
                smallOverlayButton={true}
                showActions={false}
                isLive={false}
              ></VideoCard>
            </Grid>
          ))}

        {!props.compact &&
          vods.bigVods.map((vod: any, index: number) => (
            <Grid item key={index} xs={12} sm={6} md={6}>
              <VideoCard
                title={vod.title}
                poster={vod.poster || VOD_POSTER}
                description={vod.description}
                date={vod.timeslot.start.$date}
                time={vod.time}
                href={'/vod/' + vod._id.$oid}
                showActions={false}
                isLive={false}
              ></VideoCard>
            </Grid>
          ))}
        {/* Small VOD events */}
        {!props.compact &&
          vods.smallVods.map((vod: any, index: number) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={6}
              md={3}
              style={{ alignSelf: 'center' }}
            >
              <VideoCard
                title={vod.title}
                poster={vod.poster || VOD_POSTER}
                description={vod.description}
                date={vod.timeslot.start.$date}
                time={vod.time}
                href={'/vod/' + vod._id.$oid}
                smallOverlayButton={true}
                showActions={false}
                isLive={false}
              ></VideoCard>
            </Grid>
          ))}
      </Grid>
      <Box
        mt={3}
        display='flex'
        justifyContent='center'
        alignContent='center'
        style={{ maxHeight: '50px' }}
      >
        <Button href='/search' color='secondary' variant='contained'>
          Consulta l&apos; archivio
        </Button>
      </Box>
    </Fragment>
  )
}

const arrangeLayout = (vods: any, hasLiveEvent: boolean) => {
  if (hasLiveEvent && vods.length >= 3) {
    return {
      bigVods: vods.slice(0, 1),
      smallVods: vods.slice(1, 3),
    }
  } else if (vods.length >= 3) {
    return {
      bigVods: vods.slice(0, 2),
      smallVods: vods.slice(2),
    }
  } else if (vods.length >= 1)
    return {
      bigVods: vods,
      smallVods: [],
    }
  else
    return {
      bigVods: [],
      smallVods: [],
    }
}

export default LastVods
