import { makeStyles } from '@material-ui/core'
import React, { Fragment } from 'react'
import { ArchiveSearchForm } from '../components/archive/search/ArchiveSearchForm'
import Layout from '../components/Layout'
import LiveVideos from '../components/video/live/LiveVideos'
import ScheduledEvents from '../components/video/scheduled/ScheduledEvents'
import LastVods from '../components/video/vod/LastVods'
import { LiveContextProvider } from '../providers/context/LiveContext'

const useStyles = makeStyles(theme => ({
  heroContent: {
    //backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 0, 2),
  },
  '@global': {
    body: {
      backgroundColor: 'white!important',
    },
    '.MuiButton-contained': {
      borderRadius: 15,
    },
  },
}))

export const Home: React.FC = () => {
  useStyles()

  return (
    <Fragment>
      <Layout>
        <LiveContextProvider>
          <ScheduledEvents></ScheduledEvents>
          <LiveVideos></LiveVideos>
          <LastVods></LastVods>
        </LiveContextProvider>

        <ArchiveSearchForm></ArchiveSearchForm>
      </Layout>
    </Fragment>
  )
}
