import { Grid, Typography, Box } from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { LiveCtx } from '../../../providers/context/LiveContext'
import { ArchiveService } from '../../../services/Archive/ArchiveService'
import { LiveService } from '../../../services/Live/LiveService'
import ButtonVideoCard from '../ButtonVideoCard'
import VideoCard from '../VideoCard'

const LiveVideos: React.FC = () => {
  const [lives, setLives] = useState([] as any[])
  const [assets, setAssets] = useState(null as any)
  const liveContext = useContext(LiveCtx)
  const { setLiveActive, livesFetched, setLivesFetched } = liveContext

  const getLiveEvents = (pagesize = 20) => {
    LiveService.getLives(pagesize).then((res: any) => {
      setLives(res)
      setLivesFetched(true)
      setLiveActive(res.length > 0)
    })
  }

  useEffect(() => {
    if (!livesFetched as boolean) getLiveEvents()
    if (assets === null) {
      ArchiveService.getAssets().then((res: any) => {
        setAssets(res)
      })
    }
  })

  const LIVE_POSTER = assets?.default_live_poster
    ? assets?.default_live_poster
    : 'https://synedrio-prod.s3.eu-central-1.amazonaws.com/assets/default_live_poster.jpg'

  return (
    <Fragment>
      {/* Live events */}
      <div style={{ display: lives.length ? 'inherit' : 'none' }}>
        <Box mt={3} mb={3}>
          <Typography
            component='h2'
            variant='h5'
            align='center'
            color='textPrimary'
            gutterBottom
          >
            SEDUTE LIVE
          </Typography>
        </Box>

        {bigLives(lives.slice(0, 3), LIVE_POSTER)}
        {smallLives(lives.slice(3))}
      </div>
    </Fragment>
  )
}

export default LiveVideos

function bigLives(lives: any, LIVE_POSTER: any) {
  return (
    <Grid container spacing={4} style={{ placeContent: 'center' }}>
      {lives.map((live: any) => (
        <Grid
          item
          key={live._id.$oid}
          xs={12}
          sm={12}
          md={lives.length > 2 ? 4 : 6}
          lg={lives.length > 2 ? 4 : 6}
        >
          <VideoCard
            href={`/live/${live._id.$oid}`}
            title={live.title}
            poster={live.poster_url || LIVE_POSTER}
            date={live.timeslot.start.$date}
            time={live.time}
            description={live.description}
            showActions={false}
            isLive={true}
          ></VideoCard>
        </Grid>
      ))}
    </Grid>
  )
}

function smallLives(lives: any) {
  return (
    <Box component='div' mt={3}>
      <Grid container spacing={4} style={{ placeContent: 'center' }}>
        {lives.map((card: any) => (
          <Grid item key={card._id} xs={12} sm={12} md={4} lg={4}>
            <ButtonVideoCard name='Seduta' date='11/05/2021' />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
