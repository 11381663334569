import { Box, Fab, Popover, Typography } from '@material-ui/core'
import React, { Fragment } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link } from '@material-ui/icons'

const AUTOCLOSE = 1.3 * 1000

export const CopyLinkBtn: React.FC<any> = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setTimeout(() => setAnchorEl(null), AUTOCLOSE)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Fragment>
      <CopyToClipboard text={props.link}>
        <Fab
          aria-describedby={id}
          onClick={handleClick}
          style={{
            width: props.size || 36,
            height: props.size || 36,
            boxShadow: 'none',
          }}
          size='small'
          color='primary'
          aria-label='add'
        >
          <Link />
        </Fab>
      </CopyToClipboard>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={1} display='flex' justifyContent='center'>
          <Box display='flex' alignItems='center'>
            <Typography>Link copiato!</Typography>
          </Box>
        </Box>
      </Popover>
    </Fragment>
  )
}
