import { Box } from '@material-ui/core'
import React from 'react'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'
import { CopyLinkBtn } from './copyLinkBtn'

const SharePanel: React.FC<any> = (props: any) => {
  return (
    <Box mt={2} display='flex' style={{ placeContent: 'flex-end' }}>
      <Box mr={1}>
        <FacebookShareButton url={props.url} quote={props.title}>
          <FacebookIcon size={36} round />
        </FacebookShareButton>
      </Box>
      <Box mr={1}>
        <LinkedinShareButton url={props.url}>
          <LinkedinIcon size={36} round />
        </LinkedinShareButton>
      </Box>
      <Box mr={1}>
        <TwitterShareButton url={props.url}>
          <TwitterIcon size={36} round />
        </TwitterShareButton>
      </Box>
      <Box>
        <CopyLinkBtn link={props.url}></CopyLinkBtn>
      </Box>
    </Box>
  )
}

export default SharePanel
