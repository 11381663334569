import React, { ReactElement, ReactFragment, useState } from 'react'

export interface LiveContext {
  liveActive: any
  setLiveActive: CallableFunction
  livesFetched: any
  setLivesFetched: CallableFunction
}

export const LiveCtx = React.createContext({} as LiveContext)

interface LiveContextProps {
  children: React.ReactChildren | Element | ReactFragment
}

export const LiveContextProvider = (props: LiveContextProps): ReactElement => {
  const [liveActive, setLiveActive] = useState(false)
  const [livesFetched, setLivesFetched] = useState(false)

  const liveContext: LiveContext = {
    liveActive,
    setLiveActive,
    livesFetched,
    setLivesFetched,
  }

  return (
    <LiveCtx.Provider value={liveContext}>{props.children}</LiveCtx.Provider>
  )
}
