import {
  Box,
  Link,
  Accordion,
  makeStyles,
  Typography,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { Fragment } from 'react'
import { msToHhMm } from '../../../shared/utils'

import parse from 'html-react-parser'

const useStyles = makeStyles((props: SpeakerProps) => ({
  posterText: {
    background: '#ffffffbf',
    width: '100%',
    padding: '13px',
  },
  toolbarLogo: {
    width: '50%',
    height: '100px',
    maxWidth: '325px',
    cursor: 'pointer',
  },
  logo: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  card: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    WebkitBoxShadow: '0px 1px 15px 3px rgba(0,66,112,0.29)',
    mozBoxShadow: '0px 1px 15px 3px rgba(0,66,112,0.29)',
    boxShadow: '0px 1px 15px 3px rgba(0,66,112,0.29)',
  },
  cardMediaOverlay: {
    position: 'absolute',
    paddingTop: 'calc(28% - 56px)',
    paddingBottom: 'calc(28% - 56px)',
    width: '100%',
  },
  cardMediaOverlaySm: {
    position: 'absolute',
    paddingTop: 'calc(28% - 56px)',
    paddingBottom: 'calc(28% - 56px)',
    width: '100%',
  },
  cardMedia: {
    paddingTop: '28.125%', // 16:9
    paddingBottom: '28.125%', // 16:9
    //filter: 'blur(1.4px)',
  },
  speakerAvatar: {
    backgroundPosition: 'center 15px',
    backgroundRepeat: 'no-repeat',
    backgroundImage: props.imageUrl
      ? `url(${props.imageUrl})`
      : 'url("/assets/img/utente.svg")',
    verticalAlign: 'middle',
    maxWidth: '80px',
    minWidth: '80px',
    height: '80px',
    borderRadius: '50%',
    backgroundSize: 'contain',
    border: '1px solid #00000052',
    marginRight: '10px',
  },
  speakerInfoBox: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    placeContent: 'center',
  },

  accordionSpeech: {
    border: 0,
    '& .Mui-expanded': {
      margin: 0,
      minHeight: 0,
    },
    '& .MuiAccordionSummary-root': {
      margin: 0,
      minHeight: 0,
    },
    '& .MuiAccordionSummary-content': {
      margin: '10 0 0 0',
      minHeight: 0,
    },
    '&::before': {
      display: 'none',
    },
  },
}))

interface SpeakerProps {
  id: string
  name: string
  surname: string
  group: string
  expanded: string
  handleChange: any
  minutes: string
  biography: string | null
  imageUrl: string | null
  seekCb: any
}

const MAX_SPEECH = 3
let classes = {} as any

const SessionSpeaker: React.FC<any> = (props: SpeakerProps) => {
  classes = useStyles(props)
  return (
    <Accordion
      expanded={props.expanded === props.id}
      onChange={props.handleChange(props.id)}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1c-content'
        id={props.id}
      >
        <Box display='flex' component='div'>
          {props.imageUrl ? (
            <Box
              component='div'
              className={`${classes.toolbarLogo} ${classes.speakerAvatar}`}
              style={{
                backgroundImage: `url(${props.imageUrl})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            ></Box>
          ) : (
            <Box
              component='div'
              className={`${classes.toolbarLogo} ${classes.speakerAvatar}`}
            ></Box>
          )}
          <Box className={`${classes.speakerInfoBox}`}>
            <Box fontWeight='600' display='block' component='div'>
              {props.surname} {props.name}
            </Box>
            <Box fontWeight='fontWeightMedium' display='block' component='span'>
              {props.group ? props.group : '--'}
            </Box>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Typography color='textSecondary'>
            {parse(props.biography != null ? props.biography : '')}
          </Typography>
          {renderSpeaks(props.minutes, props)}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

const renderSpeaks = (minutes: any, props: SpeakerProps) => {
  return minutes.length ? (
    <Fragment>
      <Box fontWeight='600' display='block' mt={1} component='div'>
        {minutes.length === 1 ? (
          'Intervenuto al minuto: '
        ) : (
          <span>
            Intervenuto ai minuti:<br></br>
          </span>
        )}
        {minutes.slice(0, MAX_SPEECH).map((minute: any, index: any) => (
          <Fragment key={index}>
            <Link
              onClick={() => {
                props.seekCb(minute)
              }}
              variant='body2'
            >
              {msToHhMm(minute)}
            </Link>
            <span>
              {index < minutes.slice(0, MAX_SPEECH).length - 1 ? ' - ' : ''}
            </span>
          </Fragment>
        ))}
      </Box>
      {minutes.length > MAX_SPEECH ? (
        <Accordion className={classes.accordionSpeech} elevation={0}>
          <AccordionSummary style={{ padding: 0, border: 0 }}>
            <Link
              style={{ fontWeight: 'bold' }}
              color='textPrimary'
              variant='body2'
            >
              Mostra Altri
            </Link>
          </AccordionSummary>
          <AccordionDetails>
            <Box fontWeight='600' display='block' mt={1} component='div'>
              {minutes.slice(MAX_SPEECH).map((minute: any, index: any) => (
                <Fragment key={index}>
                  <Link
                    onClick={() => {
                      props.seekCb(minute)
                    }}
                    variant='body2'
                  >
                    {msToHhMm(minute)}
                  </Link>
                  <span>
                    {index < minutes.slice(MAX_SPEECH).length - 1 ? ' - ' : ''}
                  </span>
                </Fragment>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      ) : null}
    </Fragment>
  ) : null
}

export default SessionSpeaker
