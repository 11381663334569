import {
  Box,
  Button,
  Typography,
  Container,
  makeStyles,
} from '@material-ui/core'
import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../components/Layout'

const useStyles = makeStyles(theme => ({
  heroContent: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 400px)',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 540px)',
    },
  },
}))

export const PageNotFound: React.FC = () => {
  const history = useHistory()
  const classes = useStyles()

  return (
    <Fragment>
      <Layout>
        <div className={classes.heroContent}>
          <Container maxWidth='lg'>
            <Typography
              component='h1'
              variant='h5'
              align='center'
              color='textPrimary'
              gutterBottom
            >
              La pagina non è stata trovata
            </Typography>

            <Box mt={3} display='flex' justifyContent='center'>
              <Button
                href='#'
                color='primary'
                variant='outlined'
                onClick={() => history.push('/')}
                style={{ margin: '0 auto' }}
              >
                Torna alla home
              </Button>
            </Box>
          </Container>
        </div>
      </Layout>
    </Fragment>
  )
}
