import React from 'react'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box/Box'
import { Grid, Link } from '@material-ui/core'
import { LinkRounded } from '@material-ui/icons'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export const LinksDialog: React.FC<any> = (props: any) => {
  return (
    <Dialog
      onClose={props.onClose}
      aria-labelledby='customized-dialog-title'
      open={props.open}
      maxWidth='lg'
    >
      <DialogTitle id='customized-dialog-title' onClose={props.onClose}>
        Link
      </DialogTitle>
      <DialogContent dividers>
        <Box
          display='flex'
          flexDirection='row'
          style={{
            placeContent: 'space-evenly',
            padding: '2%',
            minWidth: '200px',
          }}
        >
          <Grid
            container
            item
            spacing={4}
            md={12}
            direction='column'
            style={{ placeContent: 'flex-start' }}
          >
            {props.links?.map((link: any, index: number) => (
              <Grid item key={index}>
                <Box display='flex' alignItems='center'>
                  <LinkRounded
                    fontSize='default'
                    style={{ marginRight: '5px' }}
                  />
                  <Link href={link.url} target='_blank' variant='body2'>
                    {link.name}
                  </Link>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.onClose} color='primary'>
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  )
}
