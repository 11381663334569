import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { Fragment, useContext, useEffect } from 'react'
import { VodCtx } from '../../../providers/context/VodContext'

let vodCtx: any

let timeout: any = null
let currentWordIndex: any = null

const RESYNC_TIMEOUT = 2 * 1000

const useStyles = makeStyles(theme => ({
  transcription: {
    overflow: 'auto',
    maxHeight: '340px',
    wordBreak: 'break-all',
    scrollBehavior: 'smooth',
    '& span': {
      padding: 2,
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      marginRight: theme.spacing(0.5),
      transition: 'background-color 800ms linear',
      '&:hover': {
        textDecoration: 'underline',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '17px',
        lineHeight: '27px',
      },
    },
    '& .current': {
      backgroundColor: '#00b6ff',
    },
  },
}))

const TranscriptionTab: React.FC<any> = (props: any) => {
  const [canScroll, setCanScroll] = React.useState(true)
  const [chunks, setChunks] = React.useState(1)

  const classes = useStyles()

  vodCtx = useContext(VodCtx)

  const selectCurrentWord = () => {
    const playerPosition = vodCtx.player?.getPosition()
    let currentWord = null

    if (vodCtx?.player?.getState() === 'playing') {
      if (playerPosition && playerPosition !== 0) {
        if (!currentWordIndex) {
          currentWord = getCurrentWord()
        } else if (currentWordIndex < props.jsonTranscription.length) {
          currentWord = props.jsonTranscription[currentWordIndex]

          if (vodCtx?.player?.getState() === 'playing') {
            incrementWordIndex()
          } else resetCurrentWordIndex()
        }
        if (currentWord) {
          const oldWord = document.getElementsByClassName('current')
          oldWord[0]?.classList.remove('current')

          const wordEl: NodeListOf<any> = document.querySelectorAll(
            `[data-idtok="${currentWord.attributes.idTok}"]`,
          )
          wordEl[0]?.classList?.add('current')
          const tCont = document.getElementById('transcription-container')
          if (tCont && wordEl[0] && canScroll) {
            const tooFar = wordEl[0].offsetTop - tCont.scrollTop > 15000
            tCont.style.scrollBehavior = tooFar ? 'auto' : 'smooth'
            tCont.scrollTop = wordEl[0].offsetTop - tCont.offsetTop - 40
          }

          const currentWordDuration =
            currentWord.attributes.end / 100 - playerPosition

          const FINE_TUNING = -25

          timeout = setTimeout(() => {
            selectCurrentWord()
          }, currentWordDuration * 1000 + FINE_TUNING)
        } else {
          timeout = setTimeout(() => selectCurrentWord(), 2000)
        }
      }
    } else resetCurrentWordIndex()
  }

  const handleMouseIn = () => {
    console.log('mousein')
    clearTimeout(timeout)
    setCanScroll(false)
  }

  const handleMouseOut = () => {
    console.log('mouseout')
    timeout = setTimeout(() => {
      setCanScroll(true)
    }, RESYNC_TIMEOUT)
  }

  const seekToWord = (e: any) => {
    const wordStart = e.target.attributes['data-start'].value
    const FINE_TUNING = -0.5

    vodCtx?.player?.seek(Math.floor((+wordStart + FINE_TUNING) / 100))
    vodCtx?.player?.play()
  }

  const lazyLoadChunks = () => {
    if (chunks < props.chunks.length) {
      setTimeout(() => {
        setChunks(chunks + 40)
      }, 150)
    }
  }

  const resetCurrentWordIndex = () => {
    currentWordIndex = null
  }

  const incrementWordIndex = () => {
    if (currentWordIndex && currentWordIndex < props.jsonTranscription.length) {
      const playerPosition = vodCtx.player?.getPosition()
      if (playerPosition) {
        currentWordIndex++
      }
    }
  }

  const getCurrentWord = () => {
    const playerPosition = vodCtx.player?.getPosition() || 0
    const ALL_WORDS = props.jsonTranscription

    if (playerPosition < ALL_WORDS[0].attributes.start / 100) return null
    for (let i = 0; i < ALL_WORDS.length; i++) {
      const is_current_word =
        (playerPosition >= ALL_WORDS[i].attributes.start / 100 &&
          playerPosition < ALL_WORDS[i].attributes.end / 100) ||
        playerPosition < ALL_WORDS[i].attributes.start / 100

      if (is_current_word) {
        currentWordIndex = i
        return ALL_WORDS[i]
      }
    }
  }

  const playerEvents = () => {
    vodCtx?.player?.on('seek', () => {
      if (timeout > 0) {
        clearTimeout(timeout)
      }
      resetCurrentWordIndex()
      selectCurrentWord()
    })
    vodCtx?.player?.on('play', () => {
      if (timeout > 0) {
        clearTimeout(timeout)
      }
      resetCurrentWordIndex()
      selectCurrentWord()
    })
    vodCtx?.player?.on('pause', () => {
      if (timeout > 0) {
        clearTimeout(timeout)
      }
      resetCurrentWordIndex()
    })
  }

  useEffect(() => {
    if (props.jsonTranscription) {
      playerEvents()
      lazyLoadChunks()

      if (vodCtx?.player?.getState() === 'playing') {
        selectCurrentWord()
      } else {
        if (timeout > 0) {
          clearTimeout(timeout)
        }
        resetCurrentWordIndex()
      }
    }
    return () => {
      clearTimeout(timeout)
    }
  }, [vodCtx, canScroll])

  useEffect(() => {
    lazyLoadChunks()
  }, [chunks])

  return (
    <Fragment>
      <div
        id='transcription-container'
        onMouseEnter={handleMouseIn}
        onMouseLeave={handleMouseOut}
        onTouchStart={handleMouseIn}
        onTouchEnd={handleMouseOut}
        style={{
          display: chunks >= props.chunks.length ? 'block' : 'none',
          overflowX: 'hidden',
        }}
        className={`${classes.transcription} customScrollBar`}
      >
        {props.chunks && props.chunks.length ? (
          props.chunks
            .slice(0, chunks)
            .flat()
            .map((word: any, index: number) => {
              return word.name === 'Word' ? (
                <span
                  className='word-span'
                  key={index}
                  data-start={word.attributes.start}
                  data-idtok={word.attributes.idTok}
                  onClick={seekToWord}
                >
                  {word.elements[0].text}
                </span>
              ) : (
                <Box
                  component='span'
                  className='word-span'
                  style={{
                    display: 'block',
                    fontWeight: 'bold',
                    width: 'max-content',
                    maxWidth: '90%',
                    margin: '10px 0',
                    whiteSpace: 'normal',
                  }}
                  key={word.attributes.idTok}
                >
                  {word.elements[0].text}
                </Box>
              )
            })
        ) : (
          <Typography variant='body1'>
            Nessuna trascrizione presente.
          </Typography>
        )}
      </div>
      <Box
        component='div'
        style={{ display: chunks < props.chunks.length ? 'block' : 'none' }}
      >
        <Grid container style={{ minWidth: '250px' }}>
          <Typography variant='body1'>
            Caricamento della trascrizione...
          </Typography>
        </Grid>
      </Box>
    </Fragment>
  )
}

export default TranscriptionTab
