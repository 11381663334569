import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  buttonVideoCard: {
    width: '100%',
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
}))

const ButtonVideoCard: React.FC<any> = (props: any) => {
  const classes = useStyles()
  return (
    <Button
      variant='contained'
      color='secondary'
      className={classes.buttonVideoCard}
    >
      <Box component='div'>
        <Typography>SEGUI LA DIRETTA</Typography>
      </Box>
      <Box component='div'>
        <Typography>
          {props.name} - {props.date}
        </Typography>
      </Box>
    </Button>
  )
}

export default ButtonVideoCard
