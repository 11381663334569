import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { Description, PictureAsPdf } from '@material-ui/icons'
import { format } from 'date-fns'
import { it } from 'date-fns/locale'
import React, { useEffect, useState } from 'react'
import { SessionService } from '../../../services/Session/SessionService'
import environment from '../../../providers/environment'
import { isDemoOrConsiglioTenant } from '../../../shared/utils'

interface AttachmentsTabProps {
  attachments: { $oid: string }[]
  children?: any
  breakPoints?: any
  refresh?: any
}

const AttachmentsTab: React.FC<AttachmentsTabProps> = (
  props: AttachmentsTabProps,
) => {
  const [attachments, setAttachments] = useState(null as any)

  const retrieveAttachments = async (attachmentProp: { $oid: string }[]) => {
    const attachments = []

    for (const att of attachmentProp) {
      try {
        attachments.push(await SessionService.getAttachment(att.$oid))
      } catch (error) {
        console.error('Cannot retrieve attachment: ', att.$oid)
        console.error(error)
      }
    }
    setAttachments(attachments)
  }
  //const classes = useStyles()
  useEffect(() => {
    retrieveAttachments(props.attachments)
  }, [])

  return (
    <Box component='div'>
      {props.refresh}
      <Grid container style={{ minWidth: '250px' }}>
        {!attachments ? (
          <CircularProgress />
        ) : (
          renderAttachments(attachments, props)
        )}
      </Grid>
    </Box>
  )
}

export default AttachmentsTab

export function downloadAttachment($oid: string): void {
  const url: string =
    `${environment.config.api_url}` +
    `/${environment.tenant.tenant_name}` +
    `/attachments.files/${$oid}/binary`

  window.open(url, '_blank')
}

const EmptyAllegati = () => {
  if (isDemoOrConsiglioTenant()) {
    return (
      <a
        href='http://www.consigliograndeegenerale.sm'
        target='_blank'
        rel='noopener noreferrer'
      >
        www.consigliograndeegenerale.sm
      </a>
    )
  }
  return <Typography>Nessun allegato presente.</Typography>
}
function renderAttachments(attachments: any[], props: any) {
  return attachments.length ? (
    attachments.map((attachment: any, index: any) => (
      <Grid item key={index} xs={6} sm={6} md={4} {...props.breakPoints}>
        <Box
          display='flex'
          p={1}
          flexDirection='column'
          alignItems='center'
          component='div'
          style={{ wordBreak: 'keep-all', textAlign: 'center' }}
        >
          <IconButton
            color='primary'
            aria-label='Allegato x'
            component='div'
            size='medium'
            onClick={() => {
              downloadAttachment(attachment._id.$oid)
            }}
          >
            {renderAttachmentIcon(attachment.metadata.type)}
          </IconButton>
          <Typography
            variant='body2'
            style={{ fontWeight: 600, wordBreak: 'break-word' }}
          >
            {attachment.filename}
          </Typography>
          <Typography
            variant='subtitle2'
            color='textPrimary'
            style={{ textTransform: 'capitalize' }}
          >
            {format(new Date(attachment.uploadDate.$date), 'cccc dd.MM.yyyy', {
              locale: it,
            })}
          </Typography>
        </Box>
      </Grid>
    ))
  ) : (
    <EmptyAllegati />
  )
}

function renderAttachmentIcon(type: any) {
  switch (type) {
    case 1:
      return <PictureAsPdf fontSize='large' />
    default:
      return <Description fontSize='large' />
  }
}
