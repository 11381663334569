import React from 'react'
import { Fragment } from 'react'
import { Pagination } from '@material-ui/lab'
import { Box, createStyles, Grid, List, makeStyles } from '@material-ui/core'
import { PageChange } from '../archive/search/ArchiveSearchResults'

interface PaginatorProps {
  page: number
  count: number
  pageSize: number
  children?: any
  pageChange: PageChange
}

const useStyles = makeStyles(() =>
  createStyles({
    paginationBox: {
      display: 'flex',
      justifyContent: 'center',
    },
  }),
)

const Paginator: React.FC<PaginatorProps> = (props: PaginatorProps) => {
  const classes = useStyles()

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <List>
            <Grid container>{props.children}</Grid>
          </List>
        </Grid>
        <Box mt={4} width='100%'>
          <Grid item xs={12} className={classes.paginationBox}>
            <Pagination
              page={props.page}
              boundaryCount={1}
              count={Math.ceil(props.count / props.pageSize)}
              variant='outlined'
              shape='rounded'
              size='medium'
              onChange={props.pageChange}
            />
          </Grid>
        </Box>
      </Grid>
    </Fragment>
  )
}

export default Paginator
