import { makeStyles, Card, CardMedia, Typography, Box } from '@material-ui/core'
import React, { Fragment } from 'react'
import environment from '../../providers/environment'
import { longDateFromTimestamp } from '../../shared/utils'

const useStyles = makeStyles(() => ({
  posterText: {
    background: '#ffffffbf',
    width: '100%',
    padding: '13px',
  },
  card: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    WebkitBoxShadow: '0px 1px 15px 3px rgba(0,66,112,0.29)',
    mozBoxShadow: '0px 1px 15px 3px rgba(0,66,112,0.29)',
    boxShadow: '0px 1px 15px 3px rgba(0,66,112,0.29)',
  },
  cardMediaOverlay: {
    position: 'absolute',
    paddingTop: 'calc(28% - 56px)',
    paddingBottom: 'calc(28% - 56px)',
    width: '100%',
  },
  cardMediaOverlaySm: {
    position: 'absolute',
    paddingTop: 'calc(28% - 56px)',
    paddingBottom: 'calc(28% - 56px)',
    width: '100%',
  },
  cardMedia: {
    paddingTop: '28.125%', // 16:9
    paddingBottom: '28.125%', // 16:9
    //filter: 'blur(1.4px)',
  },
}))

const SuspendedEventPoster: React.FC<any> = (props: any) => {
  const classes = useStyles()
  const posterUrl =
    `${environment.config.api_url}/${environment.tenant.tenant_name}/` +
    `attachments.files/${props.poster_id}/binary`
  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.cardMedia}
        image={posterUrl}
        title={props.title}
      ></CardMedia>

      <Box
        display='flex'
        className={
          !props.smallOverlayButton
            ? classes.cardMediaOverlay
            : classes.cardMediaOverlaySm
        }
        justifyContent='center'
        alignContent='center'
      >
        <Box className={classes.posterText}>
          {props.time ? (
            <Fragment>
              <Typography
                align='center'
                gutterBottom
                variant='h5'
                component='div'
                className='bold'
              >
                La seduta riprenderà
              </Typography>
              <Typography className='fl-cap' align='center'>
                {longDateFromTimestamp(props.time)}
              </Typography>
            </Fragment>
          ) : (
            <Fragment>
              <Typography
                align='center'
                gutterBottom
                variant='h5'
                component='div'
                className='bold'
              >
                La seduta è sospesa
              </Typography>
            </Fragment>
          )}
        </Box>
      </Box>
    </Card>
  )
}

export default SuspendedEventPoster
