import { Box } from '@material-ui/core'
import React, { Fragment, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { VodCtx } from '../../../providers/context/VodContext'

interface JwPlayerProps {
  url: string
  srt: string | 'none'
  poster: string
  title: string
  autostart: boolean
  muted: boolean
}

const JwPlayer: React.FC<any> = (props: JwPlayerProps) => {
  const vodCtx = useContext(VodCtx)
  const history = useHistory()
  const {
    playerLoaded,
    setPlayerLoaded,
    setPlayer,
    setDuration,
    /*
    ltEnabled,
    duration,
    setLtEnabled,
    setIsPlaying,
    playerSeek, 
    */
  } = vodCtx

  const player = vodCtx.player as jwplayer.JWPlayer

  const getDuration = () => {
    player.once('time', () => {
      setDuration(player.getDuration())
    })

    player.once('error', (e: any) => {
      console.log('@@@@@@@@@@@\n', e)
      if (e.sourceError.details == 'manifestLoadTimeOut') {
        setPlayerLoaded(false)
      }
    })
  }

  const renderPlayer = (props: any) => {
    const initPlayer = () => {
      if (!playerLoaded) {
        const p = jwplayer('player').setup({
          playlist: [
            {
              file: props.url,
              image: props.poster,
              title: props.title,
              tracks:
                props.srt != 'none'
                  ? [
                      {
                        kind: 'captions',
                        file: props.srt,
                        label: 'Italiano',
                      },
                    ]
                  : [],
            },
          ],
          liveSyncDuration: 20,
          liveTimeout: 100,
          manifestLoadTimeout: 100,
          playbackRateControls: true,
          type: 'hls',
          floating: { dismissable: true },
          autostart: props.autostart,
          muted: props.muted,
        })

        setPlayer(p)
        setPlayerLoaded(true)
      }
    }

    const waitAndInitPlayer = () => {
      if (typeof jwplayer !== 'undefined') {
        initPlayer()
      } else {
        setTimeout(waitAndInitPlayer, 250)
      }
    }
    if (props.url != '' && props.srt != '') waitAndInitPlayer()
  }

  const seekPlayerToQueryParam = () => {
    const query = new URLSearchParams(window.location.search)
    const seek = query.get('seek')
    if (seek && player) {
      if (player.getPosition() !== +seek) {
        player.once('play', () => {
          player.seek(+seek)
          setTimeout(() => {
            history.replace({
              search: '',
            })
          }, 1000)
        })
      }
    }
  }

  useEffect(() => {
    renderPlayer(props)
    if (player) {
      getDuration()
      seekPlayerToQueryParam()
    }
  })

  return (
    <Fragment>
      <Box id='player' component='div'></Box>
    </Fragment>
  )
}

export default JwPlayer
