import { Typography, Box, Grid, CircularProgress } from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { VodContext, VodCtx } from '../../../providers/context/VodContext'
import { SessionService } from '../../../services/Session/SessionService'
import SessionSpeaker from './SessionSpeaker'
import { isDemoOrConsiglioTenant } from '../../../shared/utils'
let vodCtx: VodContext

const SessionSpeakerList: React.FC<any> = (props: any) => {
  const [firstSpeakerGranConsiglio, setFirstSpeakerGranConsiglio] = useState(
    null as any,
  )
  const [sessionSpeakers, setSessionSpeakers] = useState(null as any)
  const [expanded, setExpanded] = React.useState<string | false>(false)
  const [refresh, setRefresh] = useState(Date.now())

  const REFRESH_RATE = 20 * 1000

  vodCtx = useContext(VodCtx)

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<any>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false)
  }

  useEffect(() => {
    SessionService.getSessionSpeakers(props.sessionId).then((res: any) => {
      //console.log(res)
      //sort by surname
      res.sort((a: any, b: any) => a.surname.localeCompare(b.surname))
      const sortedRes = [...res]
      if (!isDemoOrConsiglioTenant()) {
        setSessionSpeakers(sortedRes)
      } else {
        //in the case of gran consiglio we want to separate the first speaker from the rest
        setFirstSpeakerGranConsiglio(sortedRes.shift())
        setSessionSpeakers(sortedRes)
      }
    })

    if (props.isLive) {
      setTimeout(() => setRefresh(Date.now()), REFRESH_RATE)
    }
  }, [refresh])

  const getFirstRow = (speaker: any) => {
    if (!isDemoOrConsiglioTenant()) {
      return null
    }
    if (!speaker) {
      console.log('isDemoOrConsiglioTenant true but speaker is null')
      return null
    }

    return (
      <Box
        mt={3}
        mb={5}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <SessionSpeaker
          name={speaker.name}
          surname={speaker.surname}
          group={speaker.group_name}
          biography={speaker.biography}
          imageUrl={speaker.pictures}
          id={speaker._id.$oid}
          expanded={expanded}
          handleChange={handleChange}
          seekCb={(minute: number) => {
            vodCtx.playerSeek(minute)
          }}
          minutes={getSpeakerMinutes(speaker)}
        ></SessionSpeaker>
      </Box>
    )
  }
  return (
    <Fragment>
      {/* Big VOD events */}
      <Box mt={3} mb={5}>
        <Typography
          component='h2'
          variant='h5'
          align='center'
          color='textPrimary'
          gutterBottom
        >
          ORATORI INTERVENUTI
        </Typography>
      </Box>
      {firstSpeakerGranConsiglio
        ? getFirstRow(firstSpeakerGranConsiglio)
        : null}
      <Grid container spacing={4} style={{ placeContent: 'center' }}>
        {sessionSpeakers?.length > 0 ? (
          sessionSpeakers.map((speaker: any) => (
            <Grid
              item
              key={speaker._id.$oid}
              sm={6}
              md={4}
              lg={3}
              style={{ width: '100%' }}
            >
              <SessionSpeaker
                name={speaker.name}
                surname={speaker.surname}
                group={speaker.group_name}
                biography={speaker.biography}
                imageUrl={speaker.pictures}
                id={speaker._id.$oid}
                expanded={expanded}
                handleChange={handleChange}
                seekCb={(minute: number) => {
                  vodCtx.playerSeek(minute)
                }}
                minutes={getSpeakerMinutes(speaker)}
              ></SessionSpeaker>
            </Grid>
          ))
        ) : !sessionSpeakers ? (
          <CircularProgress />
        ) : (
          <Typography variant='body1'>Nessuno speaker trovato</Typography>
        )}
      </Grid>
    </Fragment>
  )
}

const getSpeakerMinutes = (speaker: any): number[] => {
  const result = []
  for (const minute of speaker.minutes) {
    result.push(minute.relative)
  }
  return result
}

export default SessionSpeakerList
