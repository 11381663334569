import differenceInYears from 'date-fns/differenceInYears'
import startOfYear from 'date-fns/startOfYear'
import endOfYear from 'date-fns/endOfYear'
import { it } from 'date-fns/locale'
import { format } from 'date-fns'
import environment from '../providers/environment'

export function searchParamsToJSON(): any {
  const obj: any = location.search
    .slice(1)
    .split('&')
    .map(p => p.split('='))
    .reduce(
      (obj, [key, value]) => ({
        ...obj,
        [key]: value != 'null' && key != '' ? value : null,
      }),
      {},
    )
  delete obj['']
  return obj
}

export function msToHhMm(ms: number): string {
  return new Date(ms * 1000).toISOString().substr(11, 8)
}

export function milliSecondsToHhMm(seconds: number): string {
  return new Date(seconds).toISOString().substr(11, 8)
}

export function validValueInJson(json: Record<any, any>): any {
  for (const k of Object.keys(json)) {
    if (![null, '', undefined].includes(json[k])) return true
  }
  return false
}

export function zuluDateToMsTimestamp(zuluDate: string): Date {
  return new Date(zuluDate)
}

export function yearsFromRange(start: number, end: number): any {
  const n: number = differenceInYears(end, start)
  const startYear = new Date(start).getFullYear()
  const results = [startYear]

  for (let i = 1; i <= n; i++) {
    results.push(+startYear + i)
  }

  return results
}

export function rangeFromYear(year: string | number): { start: any; end: any } {
  const date = new Date(year.toString())
  return {
    start: startOfYear(date).getTime(),
    end: endOfYear(date).getTime(),
  }
}

export function longDateFromTimestamp(timestamp: number): string {
  return format(new Date(timestamp), "cccc dd.MM.yyyy - 'ore' HH.mm", {
    locale: it,
  })
}

export function isDemoOrConsiglioTenant(): boolean {
  return (
    environment.tenant.tenant_name === 'demo' ||
    environment.tenant.tenant_name === 'consigliograndeegenerale'
  )
}
