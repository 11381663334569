import { makeStyles } from '@material-ui/core'
import React from 'react'
import Carousel, { CarouselProps } from 'react-material-ui-carousel'

const useStyles = makeStyles(() => ({
  '@global': {
    '.cardCarousel': {
      '& button': {
        background: 'white!important',
        color: 'black',
      },
    },
    'div[class^="Carousel-buttonWrapper"] > button': {
      top: 'calc(50% - 75px) !important',
    },
  },
}))

export const MatCarousel: React.FC<CarouselProps> = (props: CarouselProps) => {
  useStyles()
  return (
    <Carousel
      swipe={true}
      className='cardCarousel'
      autoPlay={props.autoPlay}
      animation={props.animation}
      indicators={props.indicators}
      timeout={props.timeout}
      navButtonsAlwaysVisible={props.navButtonsAlwaysVisible}
      navButtonsAlwaysInvisible={props.navButtonsAlwaysInvisible}
    >
      {props.children}
    </Carousel>
  )
}
