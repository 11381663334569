import React, { ReactElement, ReactFragment } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { Navbar } from './Navbar'
import { Footer } from './Footer'

interface LayoutProps {
  children: React.ReactChildren | Element | ReactFragment
}

const useStyles = makeStyles(theme => ({
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  '@global': {
    body: { background: 'white' },
    html: { background: 'white' },
  },
}))

export default function Layout(props: LayoutProps): ReactElement {
  const classes = useStyles()

  return (
    <React.Fragment>
      <CssBaseline />
      <Navbar />
      <main>
        <Container className={classes.cardGrid} maxWidth='lg'>
          {props.children}
        </Container>
      </main>

      {/* Footer */}
      <Footer></Footer>
    </React.Fragment>
  )
}
