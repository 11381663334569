import { MGMT_API } from '../Api'
import env from '../../providers/environment'

export class ScheduledService {
  static async getScheduledEvent(): Promise<any> {
    try {
      const searchResponse = await MGMT_API.get(
        `/tenants/${env.tenant.tenant_name}`,
      )
      return searchResponse.data?.public?.scheduled_event
    } catch (error) {
      console.error('Cannot retrieve Search Results: ', error)
      return []
    }
  }
}
