/* eslint-disable camelcase */
import * as mongodb from 'mongodb'

export interface Session {
  _id: string
  category: mongodb.ObjectId | number
  title: string
  source: string
  poster_url: string | null
  vod_url?: string
  status?: any
  live_url?: string
  number?: number
  events: Array<any> | []
  timeslot: {
    start: { $date: any } | null
    end: { $date: any } | null
  }
  deleted: boolean
}

export interface PendingSession extends Session {
  status: SessionStatusType.PENDING
}

export interface ActiveSession extends Session {
  status: SessionStatusType.ACTIVE | SessionStatusType.SUSPENDED
  live_url: string
}

export interface VodSession extends Session {
  status: SessionStatusType.VOD
  vod_file_name: string
  captions?: mongodb.ObjectID | null
  transcription?: mongodb.ObjectID | null
  attachments?: mongodb.ObjectID[] | string[]
  published: boolean
}

export enum SessionStatusType {
  PENDING = 1,
  ACTIVE = 2,
  SUSPENDED = 3,
  VOD = 4,
}
