import {
  Box,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import Layout from '../components/Layout'
import SessionSpeakerList from '../components/session/speakers/SessionSpeakerList'
import JwPlayer from '../components/video/player/JwPlayer'
import environment from '../providers/environment'
import { Session } from '../models/session'
import { SessionService } from '../services/Session/SessionService'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { VideoSideBox } from '../components/video/VideoSideBox'
import SharePanel from '../components/video/share/sharePanel'
import LastVods from '../components/video/vod/LastVods'
import { ArchiveSearchForm } from '../components/archive/search/ArchiveSearchForm'
import { format } from 'date-fns'
import { it } from 'date-fns/locale'
import { ArrowBack } from '@material-ui/icons'
import { ArchiveService } from '../services/Archive/ArchiveService'
import { VodCtx } from '../providers/context/VodContext'

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(2, 0, 2),
  },
  '@global': {
    body: {
      backgroundColor: 'white!important',
    },
    '.MuiButton-contained': {
      borderRadius: 15,
    },
    '.MuiTabs-flexContainer': {
      overflow: 'hidden!important',
    },
    '.MuiTabs-flexContainer .MuiTab-root': {
      minWidth: 'unset!important',
    },
  },
  tabPanelContainer: {
    maxHeight: '370px',
    height: '100vh',
    overflowY: 'auto',
    minWidth: 'unset!important',
  },
  videoSideBox: {
    minHeight: '450px',
    [theme.breakpoints.down('md')]: {
      flexGrow: '1',
      maxWidth: 'unset',
      padding: '16px 0!important',
    },
  },
  videoPlayerBox: {
    [theme.breakpoints.down('md')]: {
      padding: '16px 0!important',
    },
  },
  subtitleDate: {
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  lastVods: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
  },
  sessionSpeakerList: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
  },
  archiveSearchForm: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
  },
}))

export const Vod: React.FC = () => {
  const [session, setSession] = useState({} as Session)
  const [srtUrl, setSrtUrl] = useState('')
  const [assets, setAssets] = useState(null as any)
  const [locationState, setLocationState] = useState(null as any)

  const params: any = useParams()
  const history = useHistory()
  const location: any = useLocation()

  const vodCtx = useContext(VodCtx)

  const classes = useStyles()
  const url = `${window.location.origin}/vod/${params.id}`

  useEffect(() => {
    setLocationState(location.state)
    SessionService.getSession(params.id).then((res: any) => {
      if (!res) return history.push('/404')
      setSession(res)

      const VIDEO_SRT = res.captions
        ? environment.config.api_url +
          '/' +
          environment.tenant.tenant_name +
          '/attachments.files/' +
          res.captions.$oid +
          '/binary'
        : 'none'

      setSrtUrl(VIDEO_SRT)
    })

    if (assets === null) {
      ArchiveService.getAssets().then((res: any) => {
        setAssets(res)
      })
    }
    return () => {
      vodCtx.player?.stop()
      vodCtx.player?.remove()
    }
  }, [])

  const VOD_POSTER = assets?.default_vod_poster
    ? assets?.default_vod_poster
    : 'https://synedrio-prod.s3.eu-central-1.amazonaws.com/assets/default_vod_poster.jpg'

  return (
    <Fragment>
      <Layout>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth='lg'>
            <Box
              display={locationState?.fromSearch ? 'flex' : 'none'}
              justifyContent='flex-start'
              alignItems='center'
            >
              <Tooltip title='Torna alla ricerca' aria-label='back-to-search'>
                <IconButton
                  color='primary'
                  aria-label='Torna alla homepage'
                  component='span'
                  onClick={() => history.push(locationState?.fromSearchUrl)}
                  size='medium'
                >
                  <ArrowBack fontSize='large' />
                </IconButton>
              </Tooltip>
            </Box>
            <Typography
              component='h2'
              variant='h4'
              style={{
                fontWeight: 600,
                fontSize: '1.7rem',
                textTransform: 'uppercase',
              }}
              align='center'
              color='textPrimary'
              gutterBottom
            >
              {session.title}
              {session.number != null ? ' - SEDUTA N. ' + session.number : ''}
            </Typography>

            <Typography
              component='h2'
              variant='body1'
              align='center'
              color='textPrimary'
              className={classes.subtitleDate}
              gutterBottom
            >
              {session?.timeslot?.start?.$date
                ? format(
                    new Date(session.timeslot.start.$date),
                    "cccc dd.MM.yyyy - 'ore' HH.mm",
                    {
                      locale: it,
                    },
                  )
                : null}
            </Typography>

            <Grid
              container
              spacing={4}
              style={{
                placeContent: 'start',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <Grid
                item
                key={1}
                xs={12}
                sm={12}
                md={12}
                lg={7}
                className={classes.videoPlayerBox}
              >
                {/* 
                TO REMOVE HARDCODED URL 
                HERE JUST FOR TESTING PURPOSE ON TRANSCRIPTIONS
                */}
                <JwPlayer
                  url={session.vod_url}
                  srt={srtUrl}
                  poster={VOD_POSTER}
                  autostart={true}
                  muted={false}
                  title={session.title}
                ></JwPlayer>
                <SharePanel url={url} title={session.title}></SharePanel>
              </Grid>
              <VideoSideBox session={session}></VideoSideBox>
            </Grid>
            <Box className={classes.sessionSpeakerList}>
              <SessionSpeakerList sessionId={params.id}></SessionSpeakerList>
            </Box>
            <Box className={classes.lastVods}>
              <LastVods compact={true}></LastVods>
            </Box>
            <Box className={classes.archiveSearchForm}>
              <ArchiveSearchForm></ArchiveSearchForm>
            </Box>
          </Container>
        </div>
      </Layout>
    </Fragment>
  )
}
