import React, { ReactElement, ReactFragment, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ArchiveService } from '../../services/Archive/ArchiveService'

interface SearchContextProps {
  children: React.ReactChildren | Element | ReactFragment
}

export interface SearchContext {
  search: any
  currentPage: any
  sessionResults: any
  attachmentsResults: any
  setPage: CallableFunction
  setCurrentPage: CallableFunction
  isArchive: boolean
  mergedResults: any
  setMergedResults: CallableFunction
  setIsArchive: CallableFunction
}

export const SearchCtx = React.createContext({} as SearchContext)

export const SearchContextProvider = (
  props: SearchContextProps,
): ReactElement => {
  const [sessionResults, setSessionResults] = useState([])
  const [mergedResults, setMergedResults] = useState([] as any[])
  const [attachmentsResults, setAttachmentsResults] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [isArchive, setIsArchive] = useState(null as any)

  const history = useHistory()

  const search = (paramsJson: any, resetPage = false) => {
    if (paramsJson) {
      if (paramsJson.keywords)
        paramsJson.keywords = paramsJson.keywords.replace(/\+/g, ' ')
      ArchiveService.search(paramsJson)
        .then((res: any) => {
          setSessionResults(res.sessions)
          setAttachmentsResults(res.attachments)
          setCurrentPage(paramsJson?.page && !resetPage ? +paramsJson.page : 1)
          setMergedResults(combineResults(res.sessions, res.attachments))
          setIsArchive(mergedResults?.length <= 0)
          /* const head: any = document.getElementById('search-head')
        head?.scrollIntoView({ behavior: 'smooth' }) */
        })
        .catch((e: any) => {
          console.error(e)
          setMergedResults([])
        })
    } else {
      setMergedResults([])
    }
  }

  const setPage = (page: number, isArchive = false) => {
    if (!isArchive) {
      const queryParams = new URLSearchParams(location.search)
      queryParams.set('page', page.toString())
      history.push({
        search: queryParams.toString(),
        state: {
          fromSearch: true,
          fromSearchUrl: '/search' + location.search,
        },
      })
    }
    setCurrentPage(page)
  }

  const SearchContext = {
    search,
    currentPage,
    setPage,
    setCurrentPage,
    sessionResults,
    attachmentsResults,
    mergedResults,
    setMergedResults,
    isArchive,
    setIsArchive,
  }

  return (
    <SearchCtx.Provider value={SearchContext}>
      {props.children}
    </SearchCtx.Provider>
  )
}

function combineResults(sessionResults: any, attachmentsResults: any): any[] {
  if (sessionResults && attachmentsResults) {
    for (const sess of sessionResults) {
      const filteredAttachments = attachmentsResults.filter(
        (a: any) => a.sessionId === sess.session._id,
      )
      if (filteredAttachments.length) {
        sess.searchAttachments = filteredAttachments
      }
    }
  }
  return prependAttachmentsResults(sessionResults, attachmentsResults)
}

function prependAttachmentsResults(
  sessionResults: any,
  attachmentsResults: any,
): any[] {
  return attachmentsResults?.length
    ? [...attachmentsResults, ...sessionResults]
    : sessionResults
}
