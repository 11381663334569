import { Box, Container, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import Layout from '../components/Layout'
import SessionSpeakerList from '../components/session/speakers/SessionSpeakerList'
import JwPlayer from '../components/video/player/JwPlayer'
import environment from '../providers/environment'
import { Session, SessionStatusType } from '../models/session'
import { SessionService } from '../services/Session/SessionService'
import { useHistory, useParams } from 'react-router-dom'
import { VideoSideBox } from '../components/video/VideoSideBox'
import { ArchiveSearchForm } from '../components/archive/search/ArchiveSearchForm'
import LastVods from '../components/video/vod/LastVods'
import { format } from 'date-fns'
import { it } from 'date-fns/locale'
import { LiveService } from '../services/Live/LiveService'
import { VodCtx } from '../providers/context/VodContext'
import SuspendedEventPoster from '../components/session/suspendedEventPoster'
import { ArchiveService } from '../services/Archive/ArchiveService'

let checkStatusTimeout: NodeJS.Timeout
const CHECK_STATUS_RATE = 5 * 1000
const REDIRECT_TO_VOD_TIMEOUT = 3 * 1000
/* import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share' */

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(2, 0, 2),
  },
  '@global': {
    body: {
      backgroundColor: 'white!important',
    },
    '.MuiButton-contained': {
      borderRadius: 15,
    },
    '.MuiTabs-flexContainer': {
      overflow: 'hidden!important',
    },
    '.MuiTabs-flexContainer .MuiTab-root': {
      minWidth: 'unset!important',
    },
    /*     [theme.breakpoints.down('md')]: {
      '.jw-flag-floating .jw-wrapper': {
        bottom: 'auto!important',
        top: '0!important',
        maxWidth: 'unset!important',
        left: 'auto',
        right: '0!important',
      },
    }, */
  },
  tabPanelContainer: {
    maxHeight: '370px',
    height: '100vh',
    overflowY: 'auto',
    minWidth: 'unset!important',
  },
  videoSideBox: {
    minHeight: '450px',
    [theme.breakpoints.down('md')]: {
      flexGrow: '1',
      maxWidth: 'unset',
      padding: '16px 0!important',
    },
  },
  videoPlayerBox: {
    [theme.breakpoints.down('md')]: {
      padding: '16px 0!important',
    },
  },
  subtitleDate: {
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },

  lastVods: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
  },
  sessionSpeakerList: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
  },
  archiveSearchForm: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
  },
}))

export const Live: React.FC = () => {
  const [session, setSession] = useState({} as Session)
  const [statusCheck, setStatusCheck] = useState(new Date())
  const [assets, setAssets] = useState(null as any)
  const [isSuspended, setIsSuspended] = useState(false)
  const [suspendedEventPoster, setSuspendedEventPoster] = useState(null as any)
  const [srtUrl, setSrtUrl] = useState('')

  const params: any = useParams()
  const history = useHistory()
  const classes = useStyles()

  const vodCtx = useContext(VodCtx)

  const checkLiveStatus = () => {
    SessionService.getSession(params.id).then((res: any) => {
      console.log('LiveStatus: ', res.status)
      switch (res.status) {
        case SessionStatusType.VOD:
          setTimeout(
            () => history.push(`/vod/${params.id}`),
            REDIRECT_TO_VOD_TIMEOUT,
          )
          break
        case SessionStatusType.SUSPENDED:
          suspendedLive()
          break
        case SessionStatusType.PENDING:
          pendingOrActiveLive()
          break
        case SessionStatusType.ACTIVE:
          pendingOrActiveLive()
          break
      }
      setStatusCheck(new Date())
    })
  }

  useEffect(() => {
    LiveService.getSuspendedPoster().then((res: any) => {
      setSuspendedEventPoster(res)
    })
    if (assets === null) {
      ArchiveService.getAssets().then((res: any) => {
        setAssets(res)
      })
      return () => {
        vodCtx.player?.stop()
        vodCtx.player?.remove()
      }
    }
  }, [isSuspended])

  useEffect(() => {
    SessionService.getSession(params.id).then((res: any) => {
      if (!res) return history.push('/404')
      setSession(res)
      if (!isSuspended && res.status === SessionStatusType.SUSPENDED)
        setIsSuspended(true)
      const VIDEO_SRT = res.captions
        ? environment.config.api_url +
          '/' +
          environment.tenant.tenant_name +
          '/attachments.files/' +
          res.captions.$oid +
          '/binary'
        : 'none'

      setSrtUrl(VIDEO_SRT)
    })

    return () => {
      clearTimeout(checkStatusTimeout)
    }
  }, [])

  useEffect(() => {
    clearTimeout(checkStatusTimeout)
    checkStatusTimeout = setTimeout(() => checkLiveStatus(), CHECK_STATUS_RATE)
  }, [statusCheck])

  const suspendedLive = () => {
    vodCtx.player?.stop()
    if (!isSuspended) setIsSuspended(true)
  }

  const pendingOrActiveLive = () => {
    if (isSuspended) window.location.reload()
  }

  const LIVE_POSTER = assets?.default_live_poster
    ? assets?.default_vod_poster
    : 'https://synedrio-prod.s3.eu-central-1.amazonaws.com/assets/default_live_poster.jpg'

  return (
    <Fragment>
      <Layout>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth='lg'>
            <Typography
              component='h2'
              variant='h4'
              style={{
                fontWeight: 600,
                fontSize: '1.7rem',
                textTransform: 'uppercase',
              }}
              align='center'
              color='textPrimary'
              gutterBottom
            >
              {session.title}
            </Typography>

            <Typography
              component='h2'
              variant='body1'
              align='center'
              color='textPrimary'
              className={classes.subtitleDate}
              gutterBottom
            >
              {session?.timeslot?.start?.$date
                ? format(
                    new Date(session.timeslot.start.$date),
                    "cccc dd.MM.yyyy - 'ore' HH.mm",
                    {
                      locale: it,
                    },
                  )
                : null}
            </Typography>
            <Grid
              container
              spacing={4}
              style={{ placeContent: 'start', alignItems: 'center' }}
            >
              {isSuspended === false && (
                <Grid
                  item
                  key={1}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={7}
                  className={classes.videoPlayerBox}
                  style={{ display: isSuspended ? 'none' : 'unset' }}
                >
                  <JwPlayer
                    url={session.live_url}
                    srt={srtUrl}
                    poster={LIVE_POSTER}
                    autostart={!isSuspended}
                    muted={false}
                    title={session.title}
                  ></JwPlayer>
                </Grid>
              )}
              {isSuspended && suspendedEventPoster && (
                <Grid
                  item
                  key={2}
                  xs={12}
                  sm={12}
                  md={12}
                  lg={7}
                  className={classes.videoPlayerBox}
                  style={{ display: !isSuspended ? 'none' : 'unset' }}
                >
                  <SuspendedEventPoster
                    time={suspendedEventPoster.time}
                    title={suspendedEventPoster.title}
                    poster_id={suspendedEventPoster.poster}
                  ></SuspendedEventPoster>
                </Grid>
              )}

              <VideoSideBox session={session} isLive={true}></VideoSideBox>
            </Grid>
            <Box className={classes.sessionSpeakerList}>
              <SessionSpeakerList
                isLive={true}
                sessionId={params.id}
              ></SessionSpeakerList>
            </Box>
            <Box className={classes.lastVods}>
              <LastVods compact={true}></LastVods>
            </Box>
            <Box className={classes.archiveSearchForm}>
              <ArchiveSearchForm></ArchiveSearchForm>
            </Box>
          </Container>
        </div>
      </Layout>
    </Fragment>
  )
}
