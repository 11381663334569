import { Box, Typography, IconButton, Link } from '@material-ui/core'
import { PictureAsPdf, Share } from '@material-ui/icons'
import LinkIcon from '@material-ui/icons/Link'
import React from 'react'

interface IndexSubArgumentProps {
  id: string
  expanded: string
  handleChange: any
  label: string
  minute: string
  minuteAttr: string
  attachments: any[]
  links: any[]
  showAttachments: any
  showLinks: any
  parentSubject: string
  children: any
  seekCb: any
  share: any
}

const SubArgument: React.FC<any> = (props: IndexSubArgumentProps) => {
  return (
    <Box
      data-minute={props.minuteAttr}
      component='div'
      display='flex'
      alignItems='center'
      style={{ placeItems: 'end' }}
    >
      <Box display='flex' component='div'>
        {props.minute ? (
          <Link onClick={props.seekCb} variant='body1'>
            {props.minute}
          </Link>
        ) : (
          '-- '
        )}
        <Box ml={2} component='span'>
          <Typography variant='body1' style={{ fontWeight: 600 }}>
            {props.label}
          </Typography>
        </Box>
      </Box>

      <Box
        display='flex'
        style={{ marginLeft: 'auto', placeContent: 'flex-start' }}
      >
        {props.links?.length > 0 && (
          <IconButton
            color='primary'
            aria-label='Condivid'
            component='span'
            size='small'
            onClick={(e: any) => {
              e.stopPropagation()
              props.showLinks(props.links)
            }}
          >
            <LinkIcon fontSize='default' />
          </IconButton>
        )}
        {props.attachments?.length > 0 && (
          <IconButton
            color='primary'
            aria-label='Condivid'
            component='span'
            size='small'
            onClick={(e: any) => {
              e.stopPropagation()
              props.showAttachments(props.attachments)
            }}
          >
            <PictureAsPdf fontSize='default' />
          </IconButton>
        )}
        <IconButton
          color='primary'
          aria-label='Condivid'
          component='span'
          size='small'
          onClick={(e: any) => {
            e.stopPropagation()
            props.share(props.minuteAttr, props.parentSubject)
          }}
        >
          <Share fontSize='default' />
        </IconButton>
      </Box>
    </Box>
  )
}

export default SubArgument
