import { Grid, Box } from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { LiveCtx } from '../../../providers/context/LiveContext'
import { ScheduledService } from '../../../services/Scheduled/ScheduledService'
import { longDateFromTimestamp } from '../../../shared/utils'
import ScheduledEventPoster from '../../session/scheduledEventPoster'

const ScheduledEvents: React.FC<any> = () => {
  const [scheduledEvent, setScheduledEvent] = useState(null as any)
  const liveContext = useContext(LiveCtx)
  const { liveActive } = liveContext

  useEffect(() => {
    ScheduledService.getScheduledEvent().then((res: any) => {
      setScheduledEvent(res)
    })
  }, [liveActive])

  return (
    <Fragment>
      {/* Big VOD events */}
      <Box mt={3} mb={3}>
        {/* <Typography
          component='h2'
          variant='h5'
          align='center'
          color='textPrimary'
          gutterBottom
        >
          PROSSIMI EVENTI
        </Typography> */}
      </Box>

      {scheduledEvent && scheduledEvent.published ? (
        <Grid container spacing={4} style={{ placeContent: 'center' }}>
          <Grid item xs={12} sm={6} md={6}>
            <ScheduledEventPoster
              poster_id={scheduledEvent.poster}
              title={scheduledEvent.title}
              date={longDateFromTimestamp(scheduledEvent.time)}
              showActions={false}
              isLive={false}
            ></ScheduledEventPoster>
          </Grid>
        </Grid>
      ) : null}
    </Fragment>
  )
}

export default ScheduledEvents
