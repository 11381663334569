import { Box, Typography, IconButton, Link } from '@material-ui/core'
import { PictureAsPdf, Share } from '@material-ui/icons'
import LinkIcon from '@material-ui/icons/Link'
import React from 'react'
interface IndexSpeakerProps {
  minute: string
  minuteAttr: string
  name: string
  surname: string
  showAttachments: any
  parentSubject: string
  attachments: any[]
  links: any[]
  showLinks: any
  seekCb: any
  share: any
}

const Speaker: React.FC<any> = (props: IndexSpeakerProps) => {
  return (
    <Box
      data-minute={props.minuteAttr}
      mb={1}
      component='div'
      display='flex'
      alignItems='center'
      style={{ placeItems: 'end' }}
    >
      <Box display='flex' component='div'>
        {props.minute ? (
          <Link onClick={props.seekCb} component='a' variant='body1'>
            {props.minute}
          </Link>
        ) : (
          '-- '
        )}
        <Box ml={2} component='span'>
          <Link
            onClick={props.seekCb}
            component='span'
            color='textPrimary'
            variant='body1'
            style={{ cursor: 'pointer', textDecorationColor: 'green' }}
          >
            <Typography variant='body1'>
              {props.surname} {props.name}
            </Typography>
          </Link>
        </Box>
      </Box>
      <Box
        display='flex'
        component='div'
        alignItems='flex-start'
        style={{ marginLeft: 'auto', placeContent: 'flex-start' }}
      >
        {props.links?.length > 0 && (
          <IconButton
            color='primary'
            aria-label='Condivid'
            component='span'
            size='small'
            onClick={(e: any) => {
              e.stopPropagation()
              props.showLinks(props.links)
            }}
          >
            <LinkIcon fontSize='default' />
          </IconButton>
        )}
        {props.attachments?.length ? (
          <IconButton
            color='primary'
            aria-label='Condivid'
            component='span'
            size='small'
            onClick={() => props.showAttachments(props.attachments)}
          >
            <PictureAsPdf fontSize='default' />
          </IconButton>
        ) : null}
        <IconButton
          color='primary'
          aria-label='Condivid'
          component='span'
          size='small'
          onClick={() =>
            props.share(
              props.minuteAttr,
              props.parentSubject,
              `${props.surname} ${props.name}`,
            )
          }
        >
          <Share fontSize='default' />
        </IconButton>
      </Box>
    </Box>
  )
}

export default Speaker
