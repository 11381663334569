import React, { useContext, useEffect } from 'react'
import itLocale from 'date-fns/locale/it'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import {
  Box,
  Typography,
  Grid,
  createStyles,
  makeStyles,
  Theme,
  TextField,
  Button,
} from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {
  ArchiveService,
  searchParams,
} from '../../../services/Archive/ArchiveService'
import { useHistory } from 'react-router-dom'
import { searchParamsToJSON } from '../../../shared/utils'
import { Speaker } from '../../../models/speaker'
import { SearchCtx } from '../../../providers/context/SearchContext'

interface OptionsState {
  legislature?: any
  category?: any
  speakerName?: any
  speakerSurname?: any
  year?: any
  month?: any
  group?: any
  startEpoch?: any
  endEpoch?: any
  keywords?: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
    },
    halfWidthField: {
      width: `calc(50% - ${theme.spacing(1) * 2}px)`,
    },
    fullWidthField: {
      width: `calc(100% - ${theme.spacing(1) * 2}px)`,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    formButtonContainer: {
      marginTop: theme.spacing(2),
      '& .button': {
        width: '100%',
      },
      '& div': {
        minWidth: 80,
        maxWidth: 150,
      },
    },
  }),
)

let history: any

export const ArchiveSearchForm: React.FC<any> = () => {
  const classes = useStyles()

  const [legislatures, setLegislatures] = React.useState([])
  const [speakers, setSpeakers] = React.useState([])
  const [groups, setGroups] = React.useState([])
  const [categories, setCategories] = React.useState([])
  const [years, setYears] = React.useState([])
  const [months, setMonths] = React.useState([])

  const searchCtx = useContext(SearchCtx)

  history = useHistory()

  const dateLocaleProps = {
    todayLabel: 'Oggi',
    cancelLabel: 'Annulla',
    clearLabel: 'Reset',
    maxDateMessage: 'Il periodo specificato non è valido',
    minDateMessage: 'Il periodo specificato non è valido',
    invalidDateMessage: 'La data specificata non è valida',
  }

  const defaultOptionsState = {} as OptionsState
  const paramsJson: any = searchParamsToJSON()

  delete paramsJson.seek

  paramsJson.keywords
    ? (paramsJson.keywords = decodeKeywordSearchPar(paramsJson.keywords))
    : paramsJson.keywords

  const [selectedOptions, setSelectedOptions] = React.useState({
    ...defaultOptionsState,
    ...paramsJson,
  })

  const optionChange = (event: any, field: string) => {
    const override: searchParams = {}
    const currentSelection = Object.assign({}, selectedOptions)

    switch (field) {
      case 'month': {
        if (!validFormValue(selectedOptions.year)) {
          override.year = '2020'
        }
        break
      }
      case 'legislature': {
        if (!validFormValue(event.target.value)) {
          override.startEpoch = undefined
          override.endEpoch = undefined
        } else {
          const selectedLegislature: any = legislatures.filter(
            (l: any) => l.id == event.target.value,
          )[0]
          if (selectedLegislature) {
            override.startEpoch = selectedLegislature.start + '000'
            override.endEpoch = selectedLegislature.end + '000'
          }
        }
      }
    }

    currentSelection[field] = ['endEpoch', 'startEpoch'].includes(field)
      ? new Date(event).getTime()
      : event.target.value

    cleanDefaultSelections(currentSelection)

    setSelectedOptions({
      ...currentSelection,
      ...override,
    })
  }

  const speakerOptionChange = (event: any) => {
    const speaker = speakerByOid(speakers, event.target.value)
    if (speaker) {
      setSelectedOptions({
        ...selectedOptions,
        speakerName: speaker.name,
        speakerSurname: speaker.surname,
        speakerId: event.target.value,
      })
    } else {
      setSelectedOptions({
        ...selectedOptions,
        speakerName: undefined,
        speakerSurname: undefined,
        speakerId: undefined,
      })
    }
  }

  const resetOptions = () => {
    setSelectedOptions(defaultOptionsState)
    if (window.location.pathname === '/search') {
      searchCtx.setIsArchive(true)
      searchCtx.setMergedResults([] as any)
      history.push({
        pathname: '/search',
        search: '',
      })
    }
  }

  const triggerSearch = () => {
    searchCtx.search(selectedOptions)
  }

  useEffect(() => {
    ArchiveService.getLegislatures().then((res: any) => {
      setLegislatures(res)
    })
    ArchiveService.getSpeakers().then((res: any) => {
      setSpeakers(res)
    })
    ArchiveService.getGroups().then((res: any) => {
      setGroups(res)
    })
    ArchiveService.getCategories().then((res: any) => {
      setCategories(res)
    })
    ArchiveService.getYears().then((res: any) => {
      setYears(res)
    })
    ArchiveService.getMonths().then((res: any) => {
      setMonths(res)
    })
  }, [])

  return (
    <React.Fragment>
      <form
        onSubmit={(e: any) => {
          e.preventDefault()
          search(selectedOptions, true, history, triggerSearch)
        }}
      >
        <Box mt={6} mb={3}>
          <Typography
            component='h2'
            variant='h5'
            align='center'
            color='textPrimary'
            gutterBottom
          >
            CERCA IN ARCHIVIO
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            {/* MESE */}
            <FormControl
              variant='outlined'
              disabled={
                validFormValue(selectedOptions.startEpoch) ||
                validFormValue(selectedOptions.endEpoch) ||
                validFormValue(selectedOptions.legislature)
              }
              className={`${classes.formControl} ${classes.halfWidthField}`}
            >
              <InputLabel id='month-label'>Mese</InputLabel>
              <Select
                labelId='month-label'
                id='month-select'
                value={selectedOptions.month || ''}
                onChange={e => optionChange(e, 'month')}
                label='Mese'
              >
                <MenuItem value=''>
                  <em>Nessuno</em>
                </MenuItem>
                {renderMonthsOptions(months)}
              </Select>
            </FormControl>

            {/* ANNO */}
            <FormControl
              variant='outlined'
              disabled={
                validFormValue(selectedOptions.startEpoch) ||
                validFormValue(selectedOptions.endEpoch) ||
                validFormValue(selectedOptions.legislature)
              }
              className={`${classes.formControl} ${classes.halfWidthField}`}
            >
              <InputLabel id='year-label'>Anno</InputLabel>
              <Select
                labelId='year-label'
                id='year-select'
                value={selectedOptions.year || ''}
                onChange={e => optionChange(e, 'year')}
                label='Anno'
              >
                <MenuItem value=''>
                  <em>Nessuno</em>
                </MenuItem>
                {renderYearsOptions(years)}
              </Select>
            </FormControl>
          </Grid>

          {/* DAL GIORNO */}
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
              <KeyboardDatePicker
                autoOk
                clearable
                disableFuture
                disableToolbar
                disabled={
                  validFormValue(selectedOptions.month) ||
                  validFormValue(selectedOptions.year) ||
                  validFormValue(selectedOptions.legislature)
                }
                required={
                  validFormValue(selectedOptions.endEpoch) &&
                  !validFormValue(selectedOptions.legislature)
                }
                className={`${classes.formControl} ${classes.fullWidthField}`}
                variant='dialog'
                inputVariant='outlined'
                format='dd/MM/yyyy'
                margin='normal'
                id='startEpoch-picker'
                label='Dal giorno'
                maxDate={dateFromTimestamp(selectedOptions.endEpoch)}
                value={
                  !validFormValue(selectedOptions.legislature)
                    ? dateFromTimestamp(selectedOptions.startEpoch) || null
                    : null
                }
                onChange={e => optionChange(e, 'startEpoch')}
                KeyboardButtonProps={{
                  'aria-label': 'Seleziona data',
                }}
                {...dateLocaleProps}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          {/* AL GIORNO */}
          <Grid item xs={12} md={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
              <KeyboardDatePicker
                autoOk
                clearable
                disableFuture
                disableToolbar
                disabled={
                  validFormValue(selectedOptions.month) ||
                  validFormValue(selectedOptions.year) ||
                  validFormValue(selectedOptions.legislature)
                }
                required={
                  validFormValue(selectedOptions.startEpoch) &&
                  !validFormValue(selectedOptions.legislature)
                }
                className={`${classes.formControl} ${classes.fullWidthField}`}
                variant='dialog'
                inputVariant='outlined'
                format='dd/MM/yyyy'
                margin='normal'
                id='endEpoch-picker'
                label='Al giorno'
                minDate={dateFromTimestamp(selectedOptions.startEpoch)}
                value={
                  !validFormValue(selectedOptions.legislature)
                    ? dateFromTimestamp(selectedOptions.endEpoch) || null
                    : null
                }
                onChange={e => optionChange(e, 'endEpoch')}
                KeyboardButtonProps={{
                  'aria-label': 'Seleziona data',
                }}
                {...dateLocaleProps}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          {/* LEGISLATURA */}
          <Grid item xs={12} md={3}>
            <FormControl
              variant='outlined'
              disabled={
                ((validFormValue(selectedOptions.startEpoch) ||
                  validFormValue(selectedOptions.endEpoch)) &&
                  !validFormValue(selectedOptions.endEpoch)) ||
                validFormValue(selectedOptions.month) ||
                validFormValue(selectedOptions.year)
              }
              className={`${classes.formControl} ${classes.fullWidthField}`}
            >
              <InputLabel id='legislature-label-label'>Legislatura</InputLabel>
              <Select
                labelId='legislature-label'
                id='legislature-select'
                value={selectedOptions.legislature || ''}
                onChange={e => optionChange(e, 'legislature')}
                label='legislatura'
              >
                <MenuItem value=''>
                  <em>Nessuna</em>
                </MenuItem>
                {renderLegislaturesOptions(legislatures)}
              </Select>
            </FormControl>
          </Grid>

          {/* PAROLA CHIAVE */}
          <Grid item xs={12} md={3}>
            <TextField
              id='outlined-basic'
              label='Parola chiave'
              value={selectedOptions.keywords || ''}
              onChange={e => optionChange(e, 'keywords')}
              className={`${classes.formControl} ${classes.fullWidthField}`}
              variant='outlined'
            />
          </Grid>
          {/* CATEGORIA */}
          <Grid item xs={12} md={3}>
            <FormControl
              variant='outlined'
              className={`${classes.formControl} ${classes.fullWidthField}`}
            >
              <InputLabel id='category-label'>Categoria</InputLabel>
              <Select
                labelId='category-label'
                id='category-select'
                value={selectedOptions.category || ''}
                onChange={e => optionChange(e, 'category')}
                label='Categoria'
              >
                <MenuItem value=''>
                  <em>Nessuna</em>
                </MenuItem>
                {renderCategoriesOptions(categories)}
              </Select>
            </FormControl>
          </Grid>

          {/* ORATORE */}
          <Grid item xs={12} md={3}>
            <FormControl
              variant='outlined'
              className={`${classes.formControl} ${classes.fullWidthField}`}
            >
              <InputLabel id='speaker-label'>Oratore</InputLabel>
              <Select
                labelId='speaker-label'
                id='speaker-select'
                value={selectedOptions.speakerId || ''}
                onChange={e => speakerOptionChange(e)}
                label='oratore'
              >
                <MenuItem value=''>
                  <em>Nessuno</em>
                </MenuItem>
                {renderSpeakersOptions(speakers)}
              </Select>
            </FormControl>
          </Grid>

          {/* GRUPPO */}
          <Grid item xs={12} md={3}>
            <FormControl
              variant='outlined'
              className={`${classes.formControl} ${classes.fullWidthField}`}
            >
              <InputLabel id='group-label'>Gruppo</InputLabel>
              <Select
                labelId='group-label'
                id='group-select'
                value={selectedOptions.group || ''}
                onChange={e => optionChange(e, 'group')}
                label='Gruppo'
              >
                <MenuItem value=''>
                  <em>Nessuno</em>
                </MenuItem>
                {renderGroupsOptions(groups)}
              </Select>
            </FormControl>
          </Grid>
          {/* Oggetto*/}
          <Grid item xs={12} md={12}>
            <TextField
              id='outlined-basic'
              label={getLabelArgomento()}
              value={selectedOptions.subject || ''}
              onChange={e => optionChange(e, 'subject')}
              className={`${classes.formControl} ${classes.fullWidthField}`}
              variant='outlined'
            />
          </Grid>

          {/* FORM BUTTONS */}
          <Grid
            spacing={3}
            container
            justify='center'
            className={classes.formButtonContainer}
          >
            <Grid item xs={12} sm={6}>
              <Button
                onClick={() => resetOptions()}
                color='secondary'
                variant='contained'
                size='large'
                disabled={Object.keys(selectedOptions).length === 0}
                className='button'
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                type='submit'
                color='secondary'
                variant='contained'
                size='large'
                disabled={Object.keys(selectedOptions).length === 0}
                className='button'
              >
                Cerca
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  )
}
const getLabelArgomento = () => {
  const currentURL = window.location.href
  if (currentURL.includes('consigliograndeegenerale')) {
    return 'Comma'
  }
  return 'Argomento'
}
const renderLegislaturesOptions = (legislatures: any) => {
  return legislatures?.map((el: any, index: any) => {
    return (
      <MenuItem value={el.id} key={index}>
        {el.name}
      </MenuItem>
    )
  })
}
const renderSpeakersOptions = (speakers: any) => {
  return speakers?.map((el: any, index: any) => {
    return (
      <MenuItem value={el._id.$oid} key={index}>
        {el.surname} {el.name}
      </MenuItem>
    )
  })
}
const renderGroupsOptions = (groups: any) => {
  return groups?.map((el: any, index: any) => {
    return el.name != '' ? (
      <MenuItem value={el.name} key={index}>
        {el.name}
      </MenuItem>
    ) : null
  })
}
const renderCategoriesOptions = (categories: any) => {
  return categories?.map((el: any, index: any) => {
    return (
      <MenuItem value={el.id} key={index}>
        {el.name}
      </MenuItem>
    )
  })
}
const renderYearsOptions = (years: any) => {
  return years.map((el: any, index: any) => {
    return (
      <MenuItem value={el} key={index}>
        {el}
      </MenuItem>
    )
  })
}
const renderMonthsOptions = (months: any) => {
  return months.map((el: any, index: any) => {
    return (
      <MenuItem value={el.number} key={index}>
        {el.name}
      </MenuItem>
    )
  })
}
const search = (
  selectedOptions: any,
  resetPage = false,
  history: any,
  triggerSearch: any,
) => {
  cleanDefaultSelections(selectedOptions)

  delete selectedOptions.archive
  const anyValueSelected = Object.keys(selectedOptions).length !== 0

  if (anyValueSelected) {
    const searchPars = new URLSearchParams(selectedOptions)
    history.push({
      pathname: '/search',
      search: '?' + searchPars.toString(),
    })
    const jsonPars = searchParamsToJSON()
    if (resetPage) delete jsonPars.page
    triggerSearch(jsonPars, resetPage)
  }
}

const speakerByOid = (speakers: Speaker[], oid: string) => {
  return speakers.filter((s: any) => s._id.$oid === oid)[0]
}

const validFormValue = (value: any) => {
  return ![undefined, '', null, 0].includes(value)
}

const decodeKeywordSearchPar = (keyword: string) => {
  return decodeURI(keyword).replace(/'/g, '').replace(/\+/g, ' ')
}

const cleanDefaultSelections = (obj: any): void => {
  const ignoredKeys = ['speakerName', 'speakerSurname']
  for (const k of Object.keys(obj)) {
    if (!validFormValue(obj[k]) && !ignoredKeys.includes(k)) delete obj[k]
  }
}
const dateFromTimestamp = (timestamp: string): any => {
  return timestamp && !isNaN(+timestamp) ? new Date(+timestamp) : null
}
