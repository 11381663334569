import { API } from '../Api'

export class VodService {
  static async getVods(pageSize = 20): Promise<any> {
    try {
      const vodResponse = await API.get(
        `/sessions/_aggrs/archive?pagesize=${pageSize}`,
      )
      return vodResponse.data
    } catch (error) {
      console.error('Cannot retrieve Vods: ', error)
      return []
    }
  }
}
