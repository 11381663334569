import env from '../providers/environment'
import axios from 'axios'

export const API = axios.create({
  baseURL: `${env.config.api_url}/${env.tenant.tenant_name}`,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const SEARCH_API = axios.create({
  baseURL: `${env.config.api_url}/search/${env.tenant.tenant_name}?`,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const MGMT_API = axios.create({
  baseURL: `${env.config.api_url}/mgmt`,
  headers: {
    'Content-Type': 'application/json',
  },
})
