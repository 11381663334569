import { API } from '../Api'

export class SessionService {
  static async getSessionSpeakers(sessionId: string): Promise<any[]> {
    try {
      const sessionResponse = await this.getSession(sessionId, true)
      const sessionEvents = sessionResponse?.events
        ? sessionResponse.events
        : []

      if (!sessionEvents.length) return []

      const sessionSpeakerEvents = sessionEvents.filter(
        (e: any) => e.type === 6 && e.speaker._id,
      )

      const sessionSpeakerOids = sessionSpeakerEvents.map((e: any) => {
        return e.speaker._id.$oid
      })

      // Exluding duplicate speakers
      const speakersArray = Array.from(new Set(sessionSpeakerOids))

      // Build session speaker filter
      const _ids = getIdsArray(speakersArray)
      const sessionSpeakers = await this.getSpeakers(_ids)

      for (const sessionSpeaker of sessionSpeakers) {
        sessionSpeaker.minutes = getSpeakerMinutes(
          sessionSpeaker,
          sessionSpeakerEvents,
        )
        sessionSpeaker.group_name = getSpeakerGroupName(
          sessionSpeaker,
          sessionSpeakerEvents,
        )
      }

      return sessionSpeakers
    } catch (error) {
      console.error('Cannot retrieve Session Speakers: ', error)
      return []
    }
  }

  static async getSession(sessionId: string, invalidate = false): Promise<any> {
    try {
      const sessionResponse = await API.get(
        `sessions/${sessionId}` + (invalidate ? `?t=${Date.now()}` : ''),
      )
      return sessionResponse.data
    } catch (error) {
      console.error('Cannot retrieve Session Speakers: ', error)
      return null
    }
  }

  static async getSpeakers(_ids: any = null, invalidate = false): Promise<any> {
    try {
      const sessionResponse = await API.get(
        `/speakers/_aggrs/filteredSpeakers?avars={"_ids":${_ids}}` +
          (invalidate ? `?t=${Date.now()}` : ''),
      )
      return sessionResponse.data
    } catch (error) {
      console.error('Cannot retrieve Session Speakers: ', error)
      return []
    }
  }

  static async getAttachment(
    oid: string,
  ): Promise<{ name: string; type: number; binary_url: string } | null> {
    try {
      const attachmentResponse = await API.get('attachments.files/' + oid)
      return attachmentResponse.data
    } catch (error) {
      console.error('Cannot retrieve Session Attachment: ', error)
      return null
    }
  }

  static async getAttachmentBinary(oid: string): Promise<any> {
    try {
      const attachmentResponse = await API.get(
        'attachments.files/' + oid + '/binary',
      )
      return attachmentResponse.data
    } catch (error) {
      console.error('Cannot retrieve Session Attachment: ', error)
      return null
    }
  }

  static async getSpeakerGroups(invalidate = false): Promise<any> {
    try {
      const sessionResponse = await API.get(
        'groups/_aggrs/groups' + (invalidate ? `?t=${Date.now()}` : ''),
      )
      return sessionResponse.data
    } catch (error) {
      console.error('Cannot retrieve Groups: ', error)
      return []
    }
  }
}

function getIdsArray(array: any): string {
  let result = '['
  array.forEach((elem: any) => {
    result += '{"$oid":"'
    result += elem
    result += '"},'
  })
  result += ']'
  return result
}

function getSpeakerMinutes(speaker: any, sessionSpeakerEvents: any) {
  return sessionSpeakerEvents
    .filter((e: any) => e.speaker._id.$oid === speaker._id.$oid)
    .map((e: any) => e.time)
}

function getSpeakerGroupName(speaker: any, sessionSpeakerEvents: any) {
  return sessionSpeakerEvents
    .filter((s: any) => s.speaker._id.$oid === speaker._id.$oid)
    .map((s: any) => s.speaker.group_name)[0]
}
