import {
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Button,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box/Box'
import { Home } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import environment from '../providers/environment'
import { MGMT_API } from '../services/Api'

const useStyles = makeStyles(theme => ({
  appBar: {
    //borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      minHeight: '80px',
    },
    backgroundColor: 'white',
    paddingTop: '10px',
  },
  homeLinkBtn: {},
  link: {
    margin: theme.spacing(1, 1.5),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
      lineHeight: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
  toolbarBtnContainer: {
    width: '40%',
    textAlign: 'left',
  },
  alignRight: {
    textAlign: 'right',
  },
  logo: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  toolbarLogo: {
    width: '50%',
    height: '100px',
    maxWidth: '250px',
    cursor: 'pointer',
  },
  toolbarLogoSyn: {
    width: '100%',
    height: '40px',
    maxWidth: '141px',
    cursor: 'pointer',
    backgroundImage: "url('./assets/img/synedrio-logo.png')",
  },
}))

export const Navbar: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()

  const [tenantConf, setTenantConf] = useState(null as any)

  async function getTenantPublicConfig(): Promise<any> {
    const tenantResponse = await MGMT_API.get(
      `/tenants/${environment.tenant.tenant_name}`,
    )
    return tenantResponse
  }

  function goHome() {
    return history.push('/')
  }

  useEffect(() => {
    getTenantPublicConfig().then((res: any) => {
      if (res?.status === 200 && res?.data?.public) {
        setTenantConf(res.data.public)
      } else
        console.error(
          'Cannot retrieve tenant public configuration: ' +
            JSON.stringify(res, null, 4),
        )
    })
  }, [])

  useEffect(() => {
    if (tenantConf?.site_title) {
      document.title = tenantConf.site_title
    }
  }, [tenantConf])

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position='static'
        color='default'
        elevation={0}
        className={classes.appBar}
      >
        <Toolbar className={classes.toolbar}>
          <Box
            display='flex'
            className={`${classes.toolbarBtnContainer}`}
            justifyContent='flex-start'
            alignItems='center'
          >
            <IconButton
              color='primary'
              aria-label='Torna alla homepage'
              component='span'
              className={classes.homeLinkBtn}
              onClick={goHome}
              size='medium'
            >
              <Home fontSize='large' />
            </IconButton>

            <Box
              component='span'
              ml={9}
              onClick={goHome}
              display={{ xs: 'none', sm: 'none', md: 'flex' }}
              className={`${classes.toolbarLogoSyn} ${classes.logo}`}
            ></Box>
          </Box>
          {tenantConf ? (
            <Box
              component='span'
              onClick={goHome}
              className={`${classes.toolbarLogo} ${classes.logo}`}
              style={{
                backgroundImage: `url('${tenantConf.assets.logo_url}')`,
              }}
            ></Box>
          ) : (
            <span></span>
          )}

          <Box
            display='flex'
            justifyContent='flex-end'
            className={`${classes.toolbarBtnContainer}`}
            alignContent='end'
          >
            <Button
              href={environment.config.admin_app_url}
              target='_blank'
              color='primary'
              variant='outlined'
              className={classes.link}
            >
              Area Riservata
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}
