import { API, MGMT_API } from '../Api'
import env from '../../providers/environment'

export class LiveService {
  static async getLives(pageSize = 20): Promise<any> {
    try {
      const vodResponse = await API.get(
        `/sessions/_aggrs/live?pagesize=${pageSize}`,
      )
      return vodResponse.data
    } catch (error) {
      console.error('Cannot retrieve Lives: ', error)
      return []
    }
  }

  static async getSuspendedPoster(): Promise<any> {
    try {
      const searchResponse = await MGMT_API.get(
        `/tenants/${env.tenant.tenant_name}`,
      )
      return searchResponse.data?.public?.suspended_event
    } catch (error) {
      console.error('Cannot retrieve Search Results: ', error)
      return []
    }
  }
}
