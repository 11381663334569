import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  capitalize,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import {
  InsertDriveFile,
  MicRounded,
  PlayArrow,
  Description,
} from '@material-ui/icons'
import React, { Fragment, useContext, useEffect } from 'react'
import Paginator from '../../paginator/Paginator'
import { format } from 'date-fns'
import { it } from 'date-fns/locale'
import { msToHhMm, milliSecondsToHhMm } from '../../../shared/utils'
import { SearchCtx } from '../../../providers/context/SearchContext'
import { useHistory } from 'react-router-dom'

export declare type PageChange = (
  event: React.ChangeEvent<unknown>,
  value: number,
) => void

let scrollTimeout: NodeJS.Timeout

const useStyles = makeStyles(() => ({
  subContent: {
    paddingLeft: '20px',
  },
  accordionSpeech: {
    border: 0,
    '& .Mui-expanded': {
      minHeight: 0,
    },
    '& .MuiAccordionSummary-root': {
      minHeight: 0,
    },
    '& .MuiAccordionSummary-content': {
      margin: '10px 0 0 0',
      minHeight: 0,
    },
    '&::before': {
      display: 'none',
    },
  },
}))

let classes: any = {}
let history: any

interface SearchSession {
  _id: string
  vod_file_name: string
  published: true
  status: number
  captions: {
    $oid: string
  }
  transcription: null
  attachments: string[]
  title: string
  poster_url: string | null
  deleted: boolean
  category: number
  source: string
  timeslot: {
    start: {
      $date: number
    }
    end: {
      $date: number
    }
  }
  date?: { $date: number }
}

export interface ArchiveSearchResultsProps {
  results: any
}

export const ArchiveSearchResults: React.FC<any> = (props: any) => {
  classes = useStyles()

  const PAGE_SIZE = 12
  const searchCtx = useContext(SearchCtx)

  history = useHistory()

  const pageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    searchCtx.setPage(value)
  }

  useEffect(() => {
    const head: any = document.getElementById('search-head')
    clearTimeout(scrollTimeout)
    scrollTimeout = setTimeout(
      () =>
        window.requestAnimationFrame(() =>
          head.scrollIntoView({ behavior: 'smooth' }),
        ),
      200,
    )
    return () => {
      clearTimeout(scrollTimeout)
    }
  }, [searchCtx.mergedResults])

  /* */

  return (
    <React.Fragment>
      <Box component='div' pb={0} mb={0} mt={5}>
        <Typography id='search-head' variant='h6' style={{ fontWeight: 500 }}>
          Risultati ricerca:
        </Typography>
      </Box>
      {searchCtx.mergedResults ? (
        <Paginator
          page={searchCtx.currentPage}
          count={searchCtx.mergedResults.length}
          pageSize={PAGE_SIZE}
          pageChange={pageChange}
        >
          {renderResults(
            searchCtx.mergedResults,
            searchCtx.currentPage,
            PAGE_SIZE,
            props,
          )}
        </Paginator>
      ) : (
        <Paginator
          page={1}
          count={0}
          pageSize={PAGE_SIZE}
          pageChange={pageChange}
        >
          {renderResults([], 1, PAGE_SIZE, props)}
        </Paginator>
      )}
    </React.Fragment>
  )
}

export function renderResults(
  results: any[],
  page: number,
  pageSize: number,
  props: any | { searchPars: { keywords: string } },
): any {
  const offset = page > 0 ? (page - 1) * pageSize : 0
  const columnHeight = Math.ceil(pageSize / 2)
  const firstColumnItems = results?.slice(offset, offset + columnHeight)
  const secondColumnItems = results?.slice(
    offset + columnHeight,
    offset + columnHeight * 2,
  )

  return results.length ? (
    <Grid
      container
      direction='row'
      spacing={4}
      style={{ placeContent: 'flex-start' }}
    >
      {renderResultColumn(firstColumnItems, props)}
      {renderResultColumn(secondColumnItems, props)}
    </Grid>
  ) : (
    <Typography variant='body1'>Nessun risultato.</Typography>
  )
}

function renderResultColumn(results: any[], props: any) {
  return (
    <Grid
      container
      item
      spacing={4}
      md={6}
      direction='column'
      style={{ placeContent: 'flex-start' }}
    >
      {results.map((result: any, index: number) => (
        <Grid item key={index}>
          {/* MAIN CONTENT */}
          {result.filename
            ? mainContentAttachment(result)
            : mainContent(result)}

          {/* ATTACH CONTENT */}
          {subContentAttachment(result)}
          {/* SUB CONTENT */}
          {subContentSpeaker(result, props)}
        </Grid>
      ))}
    </Grid>
  )
}

function subContentSpeaker(result: any, props: any) {
  const fromSessionEvents = result.speaker && result.speaker.fromSessionEvents
  const fromSpeakEvents = result.events && result.events.length
  const fromTranscription = result.speaker && result.speaker.fromTranscription
  if (fromSessionEvents) return fromSessionEventsSpeaker(result, props)
  else if (fromSpeakEvents) return eventsListSpeaker(result)
  else if (fromTranscription) return genericSpeaker(result, props)

  return null
}

/* 
This method need to be removed 
after component refactor 
*/
// eslint-disable-next-line
export function setHistory(h: any): void {
  history = h
}

function fromSessionEventsSpeaker(result: any, props: any) {
  return (
    <Fragment>
      <Box
        mt={1}
        display='flex'
        justifyContent='left'
        className={classes.subContent}
      >
        <IconButton
          color='primary'
          aria-label='Condivid'
          component='span'
          size='small'
        >
          <MicRounded fontSize='default' />
        </IconButton>
        <Link
          onClick={() => {
            history.push({
              pathname: `/vod/${result.session._id.$oid}`,
              search: `seek=${result.time}`,
              state: {
                fromSearch: true,
                fromSearchUrl: '/search' + location.search,
              },
            })
          }}
          color='textPrimary'
          variant='subtitle1'
          style={{ cursor: 'pointer' }}
        >
          {milliSecondsToHhMm(result.time * 1000) +
            ' | ' +
            result.speaker.fromSessionEvents.name +
            ' ' +
            result.speaker.fromSessionEvents.surname}
        </Link>
      </Box>
      <Box component='div' display='block' style={{ paddingLeft: '50px' }}>
        <Typography component='span' variant='caption'>
          {renderPhrase(result.phrase, props?.searchPars?.keywords)}
        </Typography>
      </Box>
    </Fragment>
  )
}

function eventsListSpeaker(result: any) {
  const sessionId = result._id.$oid
  const speakEvents: any[] = result.events.reverse()

  return (
    <Fragment>
      {renderSpeakEvents(speakEvents.slice(0, 7), sessionId)}
      <Box display='block'>
        {speakEvents.slice(7).length
          ? renderSpeakerAccordion(speakEvents.slice(7), sessionId)
          : null}
      </Box>
    </Fragment>
  )
}

function genericSpeaker(result: any, props: any) {
  return (
    <Fragment>
      <Box
        mt={1}
        display='flex'
        justifyContent='left'
        className={classes.subContent}
      >
        <IconButton
          color='primary'
          aria-label='Condivid'
          component='span'
          size='small'
        >
          <MicRounded fontSize='default' />
        </IconButton>
        <Link
          onClick={() => {
            history.push({
              pathname: `/vod/${result.session._id.$oid}`,
              search: `seek=${Math.floor(+result.time / 100)}`,
              state: {
                fromSearch: true,
                fromSearchUrl: '/search' + location.search,
              },
            })
          }}
          color='textPrimary'
          variant='subtitle1'
          style={{ cursor: 'pointer' }}
          /* style={{ textDecoration: 'none' }} */
        >
          {milliSecondsToHhMm(Math.floor(+result.time * 10)) +
            ' | ' +
            result.speaker?.fromTranscription?.name}
        </Link>
      </Box>
      <Box component='div' display='block' style={{ paddingLeft: '50px' }}>
        <Typography component='span' variant='caption'>
          {renderPhrase(result.phrase, props?.searchPars?.keywords)}
        </Typography>
      </Box>
    </Fragment>
  )
}

function renderPhrase(phrase: any, keywords: string) {
  const decodedKeywords = decodeURI(keywords)
  const splitted = decodedKeywords
    .replace('+', ' ')
    ?.trim()
    .split(' ')
    .filter(s => s !== '')
  let replacedPhrase = phrase

  for (const word of splitted) {
    replacedPhrase = replacedPhrase
      .toLowerCase()
      .replaceAll(
        word.toLowerCase(),
        `<span style="font-weight:bold">${word}</span>`,
      )
  }

  return <div dangerouslySetInnerHTML={{ __html: replacedPhrase }} />
}

function subContentAttachment(result: any) {
  return result.searchAttachments?.map((a: any) => (
    <Box
      mt={1}
      key={a._id.$oid}
      display='flex'
      justifyContent='left'
      className={classes.subContent}
    >
      <IconButton
        color='primary'
        aria-label="Vai all' intervento"
        component='span'
        size='small'
      >
        <InsertDriveFile fontSize='default' />
      </IconButton>
      <Link
        onClick={() => {
          history.push({
            pathname: `/vod/${a.sessionId.$oid}`,
            state: {
              fromSearch: true,
              fromSearchUrl: '/search' + location.search,
            },
          })
        }}
        color='textPrimary'
        variant='subtitle1'
      >
        {a.filename} | {longDateFromTimestamp(a.uploadDate.$date)}
      </Link>
    </Box>
  ))
}

const renderSpeakerAccordion = (events: any, sessionId: string) => {
  return (
    <Accordion
      elevation={0}
      className={classes.accordionSpeech}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        aria-controls='panel1c-content'
        style={{ maxWidth: '110px' }}
      >
        <Link
          style={{ fontWeight: 'bold' }}
          color='textPrimary'
          variant='body2'
        >
          Mostra Altri
        </Link>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'block', padding: 0 }}>
        {renderSpeakEvents(events, sessionId)}
      </AccordionDetails>
    </Accordion>
  )
}

function renderResultAccordioSpeaker(
  result: any,
  index: number,
  sessionId: string,
) {
  return (
    <Box
      mt={1}
      key={index}
      display='flex'
      justifyContent='left'
      className={classes.subContent}
    >
      <IconButton
        color='primary'
        aria-label='Condividi'
        component='span'
        size='small'
      >
        <MicRounded fontSize='default' />
      </IconButton>
      <Link
        onClick={() => {
          history.push({
            pathname: `/vod/${sessionId}`,
            search: `seek=${result.time.relative}`,
            state: {
              fromSearch: true,
              fromSearchUrl: '/search' + location.search,
            },
          })
        }}
        color='textPrimary'
        variant='subtitle1'
        style={{ cursor: 'pointer' }}
      >
        {msToHhMm(result.time.relative) +
          ' | ' +
          result.speaker.name +
          ' ' +
          result.speaker.surname}
      </Link>
    </Box>
  )
}
function renderResultAccordioSubject(
  result: any,
  index: number,
  sessionId: string,
) {
  return (
    <Box
      mt={1}
      key={index}
      display='flex'
      justifyContent='left'
      className={classes.subContent}
    >
      <IconButton
        color='primary'
        aria-label='Condividi'
        component='span'
        size='small'
      >
        <Description fontSize='default' />
      </IconButton>
      <Link
        onClick={() => {
          history.push({
            pathname: `/vod/${sessionId}`,
            search: `seek=${result.time.relative}`,
            state: {
              fromSearch: true,
              fromSearchUrl: '/search' + location.search,
            },
          })
        }}
        color='textPrimary'
        variant='subtitle1'
        style={{ cursor: 'pointer' }}
      >
        {result.subject}
      </Link>
    </Box>
  )
}
function renderSpeakEvents(speakEvents: any, sessionId: string) {
  return speakEvents.map((result: any, index: number) =>
    result.type != 4
      ? renderResultAccordioSpeaker(result, index, sessionId)
      : renderResultAccordioSubject(result, index, sessionId),
  )
}

function mainContent(result: any) {
  return (
    <Box mt={0} display='flex' justifyContent='left'>
      <IconButton
        color='primary'
        aria-label='Vai al video'
        component='span'
        size='small'
      >
        <PlayArrow fontSize='default' />
      </IconButton>
      <Link
        onClick={() => {
          history.push({
            pathname: `/vod/${resultSessionId(result)}`,
            state: {
              fromSearch: true,
              fromSearchUrl: '/search' + location.search,
            },
          })
        }}
        color='primary'
        variant='subtitle1'
        style={{ maxWidth: '330px', cursor: 'pointer' }}
      >
        {result.session ? titleAndDate(result.session) : titleAndDate(result)}
      </Link>
    </Box>
  )
}

function mainContentAttachment(result: any) {
  return (
    <Box mt={0} display='flex' justifyContent='left'>
      <IconButton
        color='primary'
        aria-label="Vai all' intervento"
        component='span'
        size='small'
      >
        <InsertDriveFile fontSize='default' />
      </IconButton>
      <Link
        onClick={() => {
          history.push({
            pathname: `/vod/${result.sessionId.$oid}`,
            state: {
              fromSearch: true,
              fromSearchUrl: '/search' + location.search,
            },
          })
        }}
        color='primary'
        variant='subtitle1'
        style={{ maxWidth: '330px', cursor: 'pointer' }}
      >
        {result.filename} | {longDateFromTimestamp(result.uploadDate.$date)}
      </Link>
    </Box>
  )
}

function titleAndDate(session: SearchSession) {
  return (
    <Fragment>
      {session.timeslot
        ? longDateFromTimestamp(session.timeslot.start?.$date)
        : longDateFromTimestamp(session.date?.$date)}
      {' - ' + session.title}
    </Fragment>
  )
}

function longDateFromTimestamp(timestamp: number | undefined): string {
  return timestamp
    ? capitalize(
        format(new Date(timestamp), 'cccc dd.MM.yyyy', {
          locale: it,
        }),
      )
    : ''
}

function resultSessionId(result: any) {
  if (result.session?._id) return result.session._id.$oid
  else if (result._id) return result._id.$oid
  else if (result.sessionId) return result.sessionId.$oid
  else return null
}
