import { createMuiTheme, Fade, ThemeProvider } from '@material-ui/core'
import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Home } from './pages/Home'
import { Live } from './pages/Live'
import { PageNotFound } from './pages/PageNotFound'
import { Search } from './pages/Search'
import { Vod } from './pages/Vod'
import { LiveContextProvider } from './providers/context/LiveContext'
import { SearchContextProvider } from './providers/context/SearchContext'
import { VodContextProvider } from './providers/context/VodContext'
import environment from './providers/environment'
import ScrollToTop from './providers/ScrollToTop'

const logTenantInfo = (env: any) => {
  console.log(
    `%c~~~ Tenant ~~~ : ${env.tenant.tenant_name}  ಠ_ಠ`,
    'color:red;font-weight:bold',
  )
}

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Titillium Web', 'Roboto'].join(','),
  },
  palette: {
    primary: {
      main: '#004270',
    },
    secondary: {
      main: '#0070BD',
    },
  },
})

const App: React.FC = () => {
  logTenantInfo(environment)
  return (
    <BrowserRouter>
      <div className='container'>
        <ThemeProvider theme={theme}>
          <ScrollToTop />
          <Fade>
            <Switch>
              <Route path='/' exact>
                <SearchContextProvider>
                  <Home />
                </SearchContextProvider>
              </Route>

              <Route path='/vod/:id?' exact>
                <SearchContextProvider>
                  <VodContextProvider>
                    <Vod />
                  </VodContextProvider>
                </SearchContextProvider>
              </Route>

              <Route path='/live/:id?' exact>
                <VodContextProvider>
                  <LiveContextProvider>
                    <Live />
                  </LiveContextProvider>
                </VodContextProvider>
              </Route>

              <Route path='/search'>
                <SearchContextProvider>
                  <Search />
                </SearchContextProvider>
              </Route>

              <Route path='' exact={true} component={PageNotFound} />
            </Switch>
          </Fade>
        </ThemeProvider>
      </div>
    </BrowserRouter>
  )
}

export default App
