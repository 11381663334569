import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core'
import { NavigateBefore, NavigateNext } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import Paginator from '../../paginator/Paginator'
import { SearchCtx } from '../../../providers/context/SearchContext'
import { ArchiveService } from '../../../services/Archive/ArchiveService'
import { renderResults, setHistory } from './ArchiveSearchResults'
import { rangeFromYear, yearsFromRange } from '../../../shared/utils'
import { useHistory } from 'react-router-dom'

export declare type PageChange = (
  event: React.ChangeEvent<unknown>,
  value: number,
) => void

export const ArchiveLegislature: React.FC<any> = () => {
  const PAGE_SIZE = 12
  const searchCtx = useContext(SearchCtx)
  const history = useHistory()

  const [currentLegislature, setCurrentLegislature] = useState(null as any)
  const [currentLegislatureYear, setCurrentLegislatureYear] = useState(
    null as any,
  )
  const [legislatures, setLegislatures] = React.useState([])
  const [legislaturesYears, setLegislaturesYears] = useState({} as any)

  /*
  Need to set history to component 'ArchiveSearchResults' 
  as methods reused in this component 
  use history in original component global scope
  NEED REFACTOR
  */
  setHistory(history)

  const pageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    searchCtx.setPage(value, true)
  }

  const nextLegislature = () => {
    if (currentLegislature.id >= 2) {
      const toSet: any = legislatures[currentLegislature.id - 2]
      setCurrentLegislature(toSet)
      setCurrentLegislatureYear(
        legislaturesYears[toSet.name][legislaturesYears[toSet.name].length - 1],
      )
    }
  }

  const previousLegislature = () => {
    if (currentLegislature.id < legislatures.length) {
      const toSet: any = legislatures[currentLegislature.id]
      setCurrentLegislature(toSet)
      setCurrentLegislatureYear(
        legislaturesYears[toSet.name][legislaturesYears[toSet.name].length - 1],
      )
    }
  }

  const search = () => {
    const searchPars = {} as any

    if (currentLegislatureYear) {
      const range = rangeFromYear(currentLegislatureYear)

      searchPars.startEpoch = range.start
      searchPars.endEpoch = range.end

      searchCtx.search(searchPars)
    }
  }

  useEffect(() => {
    const head: any = document.getElementById('legislature-head')
    searchCtx.isArchive &&
      legislatures?.length &&
      head.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
  }, [searchCtx.currentPage])

  useEffect(() => {
    if (!legislatures?.length)
      ArchiveService.getLegislatures().then((res: any) => {
        if (res) {
          setLegislatures(res)
          setCurrentLegislature(res[0])
        }
      })
    else {
      if (!Object.keys(legislaturesYears).length) {
        initLegislaturesYears(legislatures, setLegislaturesYears)
      }
    }

    if (
      currentLegislature?.name &&
      !currentLegislatureYear &&
      legislaturesYears[currentLegislature.name]
    ) {
      const selectedLegislature = legislaturesYears[currentLegislature.name]
      setCurrentLegislatureYear(
        selectedLegislature[selectedLegislature.length - 1],
      )
    }

    currentLegislature && search()
  }, [currentLegislature, legislaturesYears, currentLegislatureYear])

  return legislatures?.length ? (
    <React.Fragment>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        component='div'
        pb={0}
        mb={0}
        mt={5}
      >
        {currentLegislature && (
          <React.Fragment>
            <IconButton
              color='primary'
              aria-label='Torna alla homepage'
              component='span'
              disabled={currentLegislature.id === legislatures.length}
              onClick={() => previousLegislature()}
              size='medium'
            >
              <NavigateBefore fontSize='large' />
            </IconButton>
            <Typography
              component='span'
              variant='h6'
              style={{ fontWeight: 500 }}
              align='center'
            >
              Legislatura {currentLegislature.name}
            </Typography>
            <IconButton
              color='primary'
              aria-label='Torna alla homepage'
              component='span'
              disabled={currentLegislature.id === 1}
              onClick={() => nextLegislature()}
              size='medium'
            >
              <NavigateNext fontSize='large' />
            </IconButton>
          </React.Fragment>
        )}
      </Box>
      <Box mt={4} mb={4}>
        <Grid
          container
          spacing={1}
          id='legislature-head'
          justify='center'
          style={{ placeContent: 'center' }}
        >
          {legislatures.length &&
            currentLegislature &&
            legislaturesYears[currentLegislature.name] &&
            legislaturesYears[currentLegislature.name].map(
              (legislature: any) => (
                <Grid key={legislature} item style={{ textAlign: 'center' }}>
                  <Button
                    onClick={() => setCurrentLegislatureYear(legislature)}
                    variant='contained'
                    color={
                      currentLegislatureYear === legislature
                        ? 'secondary'
                        : 'default'
                    }
                  >
                    {legislature}
                  </Button>
                </Grid>
              ),
            )}
        </Grid>
      </Box>
      {searchCtx.sessionResults ? (
        <Paginator
          page={searchCtx.currentPage}
          count={searchCtx.sessionResults.length}
          pageSize={PAGE_SIZE}
          pageChange={pageChange}
        >
          {renderResults(
            searchCtx.sessionResults,
            searchCtx.currentPage,
            PAGE_SIZE,
            null,
          )}
        </Paginator>
      ) : (
        <Paginator
          page={1}
          count={0}
          pageSize={PAGE_SIZE}
          pageChange={pageChange}
        >
          {renderResults([], 1, PAGE_SIZE, null)}
        </Paginator>
      )}
    </React.Fragment>
  ) : null
}

function initLegislaturesYears(
  legislatures: { name: string; start: number; end: number }[],
  setLegislaturesYears: any,
) {
  const legYears = {} as any

  for (const leg of legislatures) {
    const years = yearsFromRange(leg.start * 1000, leg.end * 1000)
    legYears[leg.name] = years
  }

  setLegislaturesYears(legYears)
}
