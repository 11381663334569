import React from 'react'
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box/Box'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'
import { CopyLinkBtn } from '../../../video/share/copyLinkBtn'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export const ShareDialog: React.FC<any> = (props: any) => {
  const url = `${window.location.origin}/vod/${props.sessionId?.$oid}?seek=${props.minute}`
  const title = props.name ? `${props.subject} | ${props.name}` : props.subject
  return (
    <Dialog
      onClose={props.onClose}
      aria-labelledby='customized-dialog-title'
      open={props.open}
    >
      <DialogTitle id='customized-dialog-title' onClose={props.onClose}>
        Condividi
      </DialogTitle>
      <DialogContent dividers>
        {/* <Typography gutterBottom>
          Condividi l'intervento
        </Typography> */}
        <Box
          display='flex'
          flexDirection='row'
          style={{
            placeContent: 'space-evenly',
            padding: '5%',
            minWidth: '200px',
          }}
        >
          <Box display='flex' flexDirection='row'>
            <FacebookShareButton quote={title} url={url}>
              <FacebookIcon size={50} round={true} />
            </FacebookShareButton>
          </Box>
          <Box ml={1}>
            <LinkedinShareButton summary={title} title={title} url={url}>
              <LinkedinIcon size={50} round={true} />
            </LinkedinShareButton>
          </Box>
          <Box ml={1}>
            <TwitterShareButton title={title} url={url}>
              <TwitterIcon size={50} round={true} />
            </TwitterShareButton>
          </Box>
          <Box ml={1}>
            <CopyLinkBtn size={50} link={url}></CopyLinkBtn>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={props.onClose} color='primary'>
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  )
}
