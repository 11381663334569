import { API, MGMT_API, SEARCH_API } from '../Api'
import env from '../../providers/environment'

export interface searchParams {
  month?: string
  year?: string
  category?: string
  legislature?: string
  startEpoch?: string | null
  endEpoch?: string | null
  keyword?: string
  archive?: string
  page?: string
}

export class ArchiveService {
  static async getLegislatures(): Promise<any> {
    const legislaturesResponse = await MGMT_API.get(
      `/tenants/${env.tenant.tenant_name}`,
    )
    return legislaturesResponse.data?.public?.legislatures
  }

  static async getCategories(): Promise<any> {
    const categoriesResponse = await MGMT_API.get(
      `/tenants/${env.tenant.tenant_name}`,
    )
    return categoriesResponse.data?.public?.categories
  }

  static async getAssets(): Promise<any> {
    const posterResponse = await MGMT_API.get(
      `/tenants/${env.tenant.tenant_name}`,
    )
    return posterResponse.data?.public?.assets
  }

  static async getSpeakers(): Promise<any> {
    try {
      const sessionResponse = await API.get(
        `speakers/_aggrs/speakers?pagesize=200`,
      )
      return sessionResponse.data
    } catch (error) {
      console.error('Cannot retrieve Speakers: ', error)
      return []
    }
  }

  static async getGroups(): Promise<any> {
    try {
      const sessionResponse = await API.get(
        `groups/_aggrs/groups?&pagesize=200`,
      )
      return sessionResponse.data
    } catch (error) {
      console.error('Cannot retrieve Groups: ', error)
      return []
    }
  }

  static async getMonths(): Promise<any> {
    return [
      { name: 'Gennaio', number: 1 },
      { name: 'Febbraio', number: 2 },
      { name: 'Marzo', number: 3 },
      { name: 'Aprile', number: 4 },
      { name: 'Maggio', number: 5 },
      { name: 'Giugno', number: 6 },
      { name: 'Luglio', number: 7 },
      { name: 'Agosto', number: 8 },
      { name: 'Settembre', number: 9 },
      { name: 'Ottobre', number: 10 },
      { name: 'Novembre', number: 11 },
      { name: 'Dicembre', number: 12 },
    ]
  }

  static async getYears(): Promise<any> {
    const pastYears = 10
    const currYear = new Date().getFullYear()
    const years = []

    for (let i = 0; i <= pastYears; i++) {
      years.push(currYear - i)
    }

    return years
  }

  static async search(searchParams: searchParams): Promise<any> {
    try {
      const searchResponse = await SEARCH_API.get('', { params: searchParams })
      return searchResponse.data
    } catch (error) {
      console.error('Cannot retrieve Search Results: ', error)
      return []
    }
  }
}
