import { Box, Grid, makeStyles, Tabs } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import Tab from '@material-ui/core/Tab'
import { useParams, useHistory } from 'react-router-dom'
import { SessionEvent } from '../../models/sessionEvent'
import { SessionService } from '../../services/Session/SessionService'
import AttachmentsTab from '../session/attachments/AttachmentsTab'
import IndexTab from '../session/index/IndexTab'
import TranscriptionTab from '../session/transcription/TranscriptionTab'
import { Session } from '../../models/session'
import XmlConvert from 'xml-js'
import { isDemoOrConsiglioTenant } from '../../shared/utils'

let refreshTimeout: any

export const VideoSideBox: React.FC<any> = (props: any) => {
  const [expandedPanel, setExpandedPanel] = useState(0)
  const [sessionEvents, setsessionEvents] = useState([] as SessionEvent[])
  const [jsonTranscription, setJsonTranscription] = useState(null as any)
  const [attachments, setAttachments] = useState([] as any)
  const [refresh, setRefresh] = useState(Date.now())

  const history = useHistory()
  const classes = useStyles()
  const params: any = useParams()

  const REFRESH_RATE = 15 * 1000

  useEffect(() => {
    SessionService.getSession(params.id, true).then((res: any) => {
      if (!res) return history.push('/404')

      if (res.transcription?.$oid && jsonTranscription === null) {
        createJsonTranscription(res.transcription.$oid)
          .then(jsonTranscription => {
            setJsonTranscription(jsonTranscription)
            console.log('Json transcription created')
          })
          .catch(e => console.error('Cannot create Json transcription: ', e))
      }

      if (res.events?.length && attachments !== false) {
        const attachments = attachmentsFromSessionEvents(res.events)
        if (res.attachments?.length > 0) {
          setAttachments([...res.attachments, attachments].flat())
        } else {
          setAttachments(attachments)
        }
      }

      setsessionEvents((res as Session).events)
      if (props.isLive)
        refreshTimeout = setTimeout(() => setRefresh(Date.now()), REFRESH_RATE)
    })
    return () => {
      clearTimeout(refreshTimeout)
    }
  }, [refresh])

  const panelExpandedChange = (
    event: React.ChangeEvent<any>,
    newValue: number,
  ) => {
    setExpandedPanel(newValue)
  }

  return (
    <Fragment>
      <Grid
        item
        key={2}
        xs={12}
        sm={12}
        md={5}
        className={classes.videoSideBox}
      >
        <Box component='div' style={{ height: '100%' }}>
          {tabHeaders(expandedPanel, panelExpandedChange)}
          {indexTab(expandedPanel, sessionEvents, props.session, props.isLive)}
          {transcriptionTab(classes, expandedPanel, jsonTranscription)}
          {attachmentsTab(classes, expandedPanel, attachments)}
        </Box>
      </Grid>
    </Fragment>
  )
}

const createJsonTranscription = (oid: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    console.log('creating json transcription')
    SessionService.getAttachmentBinary(oid).then((res: string) => {
      if (res) {
        try {
          const json = XmlConvert.xml2js(res).elements as any
          return resolve(json[0].elements)
        } catch (error) {
          return reject(error)
        }
      }
      return resolve(false)
    })
  })
}

const tabHeaders = (expandedPanel: any, panelExpandedChange: any) => {
  let labelAllegati = 'Allegati'
  if (isDemoOrConsiglioTenant()) labelAllegati = 'Documentazione'
  return (
    <Tabs
      value={expandedPanel}
      variant='fullWidth'
      onChange={panelExpandedChange}
      aria-label='simple tabs example'
      style={{ overflow: 'hidden!important' }}
    >
      <Tab label='Indice' {...a11yProps(0)} />
      <Tab
        style={{ lineHeight: '17px' }}
        label='Trascrizione Interattiva'
        {...a11yProps(1)}
      />
      <Tab label={labelAllegati} {...a11yProps(2)} />
    </Tabs>
  )
}

const getChunks = (jsonTranscription: any) => {
  const CHUNK_SIZE = 500
  const chunks = []
  for (let i = 0; i < jsonTranscription.length; i += CHUNK_SIZE)
    chunks.push(jsonTranscription.slice(i, i + CHUNK_SIZE))
  return chunks
}

const attachmentsTab = (
  classes: any,
  expandedPanel: any,
  attachments: { $oid: string }[],
) => {
  return (
    <TabPanel
      value={expandedPanel}
      index={2}
      className={`${classes.tabPanelContainer} tabPaneCont customScrollBar`}
    >
      <AttachmentsTab attachments={attachments}></AttachmentsTab>
    </TabPanel>
  )
}

const indexTab = (
  expandedPanel: any,
  sessionEvents: SessionEvent[],
  session: Session,
  isLive: boolean,
) => {
  return (
    <TabPanel value={expandedPanel} index={0}>
      <IndexTab
        sessionEvents={sessionEvents}
        sessionTitle={session.title}
        sessionId={session._id}
        canSync={true}
        isLive={isLive}
      ></IndexTab>
    </TabPanel>
  )
}

const transcriptionTab = (
  classes: any,
  expandedPanel: any,
  jsonTranscription: any,
) => {
  const chunks = jsonTranscription ? getChunks(jsonTranscription) : []

  return (
    <TabPanel
      value={expandedPanel}
      index={1}
      className={`${classes.tabPanelContainer} tabPaneCont`}
    >
      <TranscriptionTab
        jsonTranscription={jsonTranscription}
        chunks={chunks}
      ></TranscriptionTab>
    </TabPanel>
  )
}

const useStyles = makeStyles(theme => ({
  heroContent: {
    padding: theme.spacing(2, 0, 2),
  },
  '@global': {
    body: {
      backgroundColor: 'white!important',
    },
    '.MuiDialog-root': {
      zIndex: '99999!important',
    },
    '.MuiButton-contained': {
      borderRadius: 15,
    },
    '.MuiTabs-flexContainer': {
      overflow: 'hidden!important',
    },
    '.MuiTabs-flexContainer .MuiTab-root': {
      minWidth: 'unset!important',
    },
    /*     [theme.breakpoints.down('md')]: {
        '.jw-flag-floating .jw-wrapper': {
          bottom: 'auto!important',
          top: '0!important',
          maxWidth: 'unset!important',
          left: 'auto',
          right: '0!important',
        },
      }, */

    ['@media (max-width:865px)']: {
      '.jw-flag-floating .jw-wrapper': {
        bottom: 'auto!important',
        top: '0!important',
        maxWidth: 'unset!important',
        left: 'auto',
        right: '0!important',
        zIndex: '99999!important',
      },
    },
  },
  tabPanelContainer: {
    maxHeight: '370px',
    height: '100vh',
    overflowY: 'auto',
    minWidth: 'unset!important',
  },
  videoSideBox: {
    minHeight: '450px',
    [theme.breakpoints.down('md')]: {
      flexGrow: '1',
      maxWidth: 'unset',
      padding: '16px 0!important',
    },
  },
  videoPlayerBox: {
    [theme.breakpoints.down('md')]: {
      padding: '16px 0!important',
    },
  },
}))

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
  className?: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      className='tabPanelParentContainer'
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box pt={2}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function attachmentsFromSessionEvents(
  sessionEvents: SessionEvent[],
): { $oid: string }[] | null {
  if (sessionEvents.length) {
    const globalAttachments = sessionEvents
      .filter((s: SessionEvent) => s.attachments.length)
      .map((s: SessionEvent) => s.attachments)
      .flat()

    return globalAttachments
  }
  return null
}
