import {
  Box,
  Accordion,
  makeStyles,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Link,
  IconButton,
} from '@material-ui/core'
import { PictureAsPdf, Share } from '@material-ui/icons'
import LinkIcon from '@material-ui/icons/Link'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'

const useStyles = makeStyles(theme => ({
  leftIconAccordion: {
    flexDirection: 'row-reverse',
    minHeight: 'unset!important',
    paddingLeft: 0,
    paddingRight: 0,
    '& .MuiAccordionSummary-expandIcon': {
      marginRight: '1px',
      placeSelf: 'flex-start',
      paddingRight: 0,
      paddingLeft: 0,
    },
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      paddingRight: 0,
      paddingLeft: 0,
      top: -1,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0',
    },
  },
  argument: {
    '&.Mui-expanded': {
      margin: 0,
      borderBottom: '1px solid gainsboro',
    },
    '&.Mui-expanded:before': {
      opacity: 1,
    },
  },
  '@global': {
    a: { cursor: 'pointer' },
  },
  accordionDetail: {
    paddingTop: 0,
  },
  argumentBox: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      wordBreak: 'break-all',
    },
  },
  actionsDesktop: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  actionsMobile: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },

  argumentChildren: {
    width: '100%',
    paddingLeft: '50px',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '25px',
    },
  },
}))

interface IndexArgumentProps {
  id: string
  expanded: string
  handleChange: any
  label: string
  minuteAttr: string
  minute: string
  expandable: boolean
  showAttachments: any
  attachments: any[]
  children: any
  seekCb: any
  share: any
}

const Argument: React.FC<any> = (props: IndexArgumentProps) => {
  const classes = useStyles()

  const share = (e: any) => {
    e.stopPropagation()
    props.share(props.minuteAttr)
  }

  return (
    <Accordion
      expanded={!props.expandable ? false : props.expanded === props.id}
      onChange={!props.expandable ? null : props.handleChange(props.id)}
      TransitionProps={{ unmountOnExit: true }}
      className={classes.argument}
      elevation={1}
    >
      <AccordionSummary
        expandIcon={
          props.expandable ? (
            <ExpandMoreIcon />
          ) : (
            <span style={{ width: 24, height: 24 }}></span>
          )
        }
        aria-controls='panel1c-content'
        className={classes.leftIconAccordion}
        id={props.id}
      >
        <Box
          display='flex'
          className={classes.argumentBox}
          component='div'
          data-minute={props.minuteAttr}
          alignItems='flex-start'
          style={{ width: '100%', paddingRight: '18px', cursor: 'pointer' }}
        >
          <Box display='flex'>
            {props.minute ? (
              <Link
                onClick={(e: any) => {
                  e.stopPropagation()
                  props.seekCb(e)
                }}
                component='div'
                variant='body1'
              >
                {props.minute}
              </Link>
            ) : (
              '-- '
            )}
            {actionButtons(props, classes.actionsMobile, share)}
          </Box>
          <Box ml={{ sm: 0, md: 2 }} pr={0} component='div'>
            <Typography
              variant='body1'
              style={{ fontWeight: 600, wordBreak: 'keep-all' }}
            >
              {props.label}
            </Typography>
          </Box>
          {actionButtons(props, classes.actionsDesktop, share)}
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetail}>
        <Box component='div' className={classes.argumentChildren}>
          {props.children}
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default Argument

const actionButtons = (props: any, class_name: any, share: any) => (
  <Box
    display='flex'
    className={class_name}
    component='div'
    style={{ marginLeft: 'auto' }}
  >
    {props.attachments?.length > 0 && (
      <IconButton
        color='primary'
        aria-label='Condivid'
        component='span'
        size='small'
        onClick={(e: any) => {
          e.stopPropagation()
          props.showAttachments(props.attachments)
        }}
      >
        <PictureAsPdf fontSize='default' />
      </IconButton>
    )}
    {props.links?.length > 0 && (
      <IconButton
        color='primary'
        aria-label='Condivid'
        component='span'
        size='small'
        onClick={(e: any) => {
          e.stopPropagation()
          props.showLinks(props.links)
        }}
      >
        <LinkIcon fontSize='default' />
      </IconButton>
    )}
    <IconButton
      color='primary'
      aria-label='Condivid'
      component='span'
      size='small'
      onClick={share}
    >
      <Share fontSize='default' />
    </IconButton>
  </Box>
)
