import {
  makeStyles,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  Box,
} from '@material-ui/core'
import { format } from 'date-fns'
import { it } from 'date-fns/locale'
import { PlayCircleFilledWhite } from '@material-ui/icons'
import React from 'react'

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  card: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    WebkitBoxShadow: '0px 1px 15px 3px rgba(0,66,112,0.29)',
    mozBoxShadow: '0px 1px 15px 3px rgba(0,66,112,0.29)',
    boxShadow: '0px 1px 15px 3px rgba(0,66,112,0.29)',
  },
  cardMediaOverlay: {
    position: 'absolute',
    paddingTop: 'calc(28% - 28px)',
    paddingBottom: 'calc(28% - 28px)',
    width: '100%',
  },
  cardMediaOverlaySm: {
    position: 'absolute',
    paddingTop: 'calc(28% - 23px)',
    paddingBottom: 'calc(28% - 23px)',
    width: '100%',
  },
  cardMedia: {
    paddingTop: '28.125%', // 16:9
    paddingBottom: '28.125%', // 16:9
    //filter: 'blur(1.4px)',
  },
  cardContent: {
    flexGrow: 1,
  },
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
    '.bold': {
      fontWeight: 600,
    },
    [theme.breakpoints.up('sm')]: {
      '.small-font > .MuiButton-label': {
        fontSize: 12,
      },
    },
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  videoCardTitle: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}))

const VideoCard: React.FC<any> = (props: any) => {
  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.cardMedia}
        image={props.poster}
        title={props.title}
      ></CardMedia>

      <Box
        display='flex'
        className={
          !props.smallOverlayButton
            ? classes.cardMediaOverlay
            : classes.cardMediaOverlaySm
        }
        justifyContent='center'
        alignContent='center'
      >
        <Button
          href={props.href}
          color='secondary'
          variant='contained'
          size={props.smallOverlayButton ? 'small' : 'large'}
          className={`${classes.link} ${
            props.smallOverlayButton ? 'small-font' : ''
          }`}
        >
          {props.isLive ? 'Segui la diretta' : 'Guarda il video'}
          {props.isLive ? (
            <Box ml={1} display='flex' alignItems='center' component='span'>
              <PlayCircleFilledWhite fontSize='default' />
            </Box>
          ) : null}
        </Button>
      </Box>

      <CardContent className={classes.cardContent}>
        <Typography
          align='center'
          gutterBottom
          variant='subtitle1'
          component='div'
          title={props.title}
          className={`${classes.videoCardTitle} bold`}
        >
          {props.title}
        </Typography>
        <Typography align='center' style={{ textTransform: 'capitalize' }}>
          {!props.mock
            ? format(new Date(props.date), 'cccc dd.MM.yyyy', {
                locale: it,
              })
            : '01-02-2021'}
        </Typography>
      </CardContent>

      {props.showActions ? (
        <CardActions>
          <Button size='small' color='primary'>
            View
          </Button>
          <Button size='small' color='primary'>
            Edit
          </Button>
        </CardActions>
      ) : (
        ''
      )}
    </Card>
  )
}

export default VideoCard
