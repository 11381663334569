const CONFIG = require('../config.json')
const DEFAULT_TENANT = 'test'
const LOCAL_HOSTNAMES = ['localhost', '127.0.0.1']

const tenantConfig = { tenant_name: DEFAULT_TENANT }

if (!LOCAL_HOSTNAMES.includes(window.location.hostname)) {
  tenantConfig.tenant_name = getTenantFromHostname()
}

const env = {
  tenant: { ...tenantConfig },
  config: { ...CONFIG },
}

interface Tenant {
  tenant_name: string
  region_name: string
  common_name: string
  logo_path: string
  admin_app_url: string
  site_url: string
}

interface Configuration {
  stage: string
  api_url: string
  admin_app_url: string
}

function getTenantFromHostname(): string {
  const hostname = location.hostname
  const splittedHostname = hostname.split('.')

  if (splittedHostname?.length === 3) {
    return splittedHostname[0]
  }
  return DEFAULT_TENANT
}

export default env as { config: Configuration; tenant: Tenant }
