import { Box, makeStyles, Typography } from '@material-ui/core'
import React, { SyntheticEvent, useContext, useEffect } from 'react'
import { SessionEvent } from '../../../models/sessionEvent'
import { VodContext, VodCtx } from '../../../providers/context/VodContext'
import { msToHhMm } from '../../../shared/utils'
import { AttachmentsDialog } from '../attachments/AttachmentsDialog'
import Argument from './items/Argument'
import Speaker from './items/Speaker'
import SubArgument from './items/SubArgument'
import { LinksDialog } from './links/LinksDialog'
import { ShareDialog } from './share/ShareDialog'
import _ from 'lodash'

let vodCtx: VodContext

interface IndexTabProps {
  sessionEvents: SessionEvent[]
  sessionId: any
  sessionTitle: string
  canSync?: boolean
  isLive?: boolean
}

class IndexEntry {
  constructor(
    public argument: {
      subject: string
      time: number
      attachments: any[]
      links: { url: string; name: string }[]
    },
    public speakers: IndexSpeaker[],
    public subArgument?: {
      subject: string
      time: number
      attachments: any[]
      links: { url: string; name: string }[]
    },
  ) {}
}

class IndexSpeaker {
  constructor(
    public name: string,
    public surname: string,
    public time: number,
    public attachments: any[],
    public links: { url: string; name: string }[],
  ) {}
}

const useStyles = makeStyles((theme: any) => ({
  tabPanelContainer: {
    maxHeight: '370px',
    height: '100vh',
    overflowY: 'auto',
    minWidth: 'unset!important',
  },
  indexScrollContainer: {
    [theme.breakpoints.down('md')]: {
      flexGrow: '1',
      maxWidth: 'unset',
      padding: '16px 0!important',
      height: '50vh!important',
      minHeight: 'unset',
    },
  },
  '@global': {
    '.highlight .MuiBox-root': {
      textDecoration: 'underline',
      textDecorationColor: 'green',
      textDecorationThickness: '2px',
    },
  },
}))

const IndexTab: React.FC<any> = (props: IndexTabProps) => {
  const [expanded, setExpanded] = React.useState(-1)
  const [indexEntries, setIndexEntries] = React.useState([] as IndexEntry[])
  const [sessionEvents, setSessionEvents] = React.useState([] as any[])
  const [closestMinute, setClosestMinute] = React.useState(-1)
  const [refresh, setRefresh] = React.useState(null as any)
  const [canSync, setCanSync] = React.useState(true)
  const [shareProps, setShareProps] = React.useState({
    open: false,
  } as {
    open: boolean
    sessionId?: any
    minute?: any
    name?: any
    subject?: string
    title?: any
  })
  const [attachmentsProps, setAttachmentsProps] = React.useState({
    open: false,
  } as {
    open: boolean
    attachments?: any
  })

  const [linksProps, setLinksProps] = React.useState({
    open: false,
  } as {
    open: boolean
    links?: any
  })

  const REFRESH_RATE = 2 * 1000
  const DEBOUNCE_TIME = 7 * 1000

  const classes = useStyles()

  let syncTimeout: NodeJS.Timeout
  let debounceTimeout: NodeJS.Timeout

  vodCtx = useContext(VodCtx)

  const syncIndexWithPlayer = (entries: any) => {
    if (!props.canSync) return
    const times = timesFromEntries(entries)

    if (vodCtx.player && vodCtx.player.getState() === 'playing') {
      const position = props.isLive
        ? vodCtx.player.getCurrentTime()
        : vodCtx.player.getPosition()
      closestMinuteAndContent(position, times).then((closestEntry: any) => {
        if (expanded !== closestEntry?.mainContent) {
          setExpanded(closestEntry?.mainContent)
          scrollToEntry(closestMinute)
        }
        if (closestMinute !== closestEntry.minute)
          setClosestMinute(closestEntry.minute)
        else scrollToEntry(closestMinute)
      })
    }
  }

  const handleMouseIn = () => {
    clearTimeout(debounceTimeout)
    setCanSync(false)
  }

  const handleMouseOut = () => {
    if (indexEntries.length)
      debounceTimeout = setTimeout(() => setCanSync(true), DEBOUNCE_TIME)
  }

  useEffect(() => {
    if (!_.isEqual(props.sessionEvents, sessionEvents)) {
      const entries = mapIndexEntries(props.sessionEvents)

      setIndexEntries(entries)
      setSessionEvents(props.sessionEvents)
    }
    if (props.sessionId && props.sessionTitle) {
      setShareProps({
        open: false,
        sessionId: props.sessionId,
        title: props.sessionTitle,
      })
    }

    if (indexEntries.length) {
      syncIndexWithPlayer(indexEntries)

      if (canSync) {
        syncTimeout = setTimeout(() => {
          setRefresh(Date.now())
        }, REFRESH_RATE)
      } else {
        clearTimeout(syncTimeout)
      }
    }

    return () => {
      clearTimeout(debounceTimeout)
      clearTimeout(syncTimeout)
    }
  }, [props.sessionEvents, sessionEvents, canSync, refresh])

  const handleChange = (panel: number) => (
    event: React.ChangeEvent<any>,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : -1)
  }

  const handleShareOpen = (minute: any, subject: string, name: string) => {
    setShareProps({ ...shareProps, open: true, minute, subject, name })
  }

  const handleShareClose = () => {
    setShareProps({
      ...shareProps,
      open: false,
      minute: undefined,
      subject: undefined,
    })
  }

  const handleAttachmentsOpen = (attachments: any) => {
    setAttachmentsProps({ open: true, attachments })
  }

  const handleAttachmentsClose = () => {
    setAttachmentsProps({ open: false })
  }

  const handleLinksOpen = (links: any) => {
    setLinksProps({ open: true, links })
  }

  const handleLinksClose = () => {
    setLinksProps({ open: false })
  }

  const playerSeek = (e: any, minute: number) => {
    clearTimeout(debounceTimeout)
    scrollToEntry(minute)
    vodCtx.playerSeek(minute)
    //debounceTimeout = setTimeout(() => setCanSync(true), DEBOUNCE_TIME * 2)
    setClosestMinute(minute)
  }

  return (
    <Box
      id='index-scroll-container'
      component='div'
      className={`customScrollBar ${classes.indexScrollContainer}`}
      onMouseEnter={handleMouseIn}
      onMouseLeave={handleMouseOut}
      onTouchStart={handleMouseIn}
      onTouchEnd={handleMouseOut}
      style={{
        scrollBehavior: 'smooth',
        paddingTop: 0,
        maxHeight: '340px',
        height: '100vh',
        overflowY: 'auto',
        minWidth: 'unset!important',
      }}
    >
      <ShareDialog onClose={handleShareClose} {...shareProps}></ShareDialog>
      <AttachmentsDialog
        onClose={handleAttachmentsClose}
        {...attachmentsProps}
      ></AttachmentsDialog>

      <LinksDialog onClose={handleLinksClose} {...linksProps}></LinksDialog>

      {/* <ShareDialog onClose={handleShareClose} open={shareOpen}></ShareDialog> */}
      {indexEntries.length ? (
        indexEntries.map((entry: IndexEntry, index: number) =>
          renderIndexItem(
            entry,
            index,
            expanded,
            handleChange,
            handleShareOpen,
            handleAttachmentsOpen,
            handleLinksOpen,
            playerSeek,
          ),
        )
      ) : (
        <Typography variant='body1'>Nessun indice disponibile.</Typography>
      )}
    </Box>
  )
}

export default IndexTab

function renderIndexItem(
  indexEntry: IndexEntry,
  index: any,
  expanded: any,
  handleChange: any,
  share: any,
  attachmentsOpen: any,
  linksOpen: any,
  playerSeek: any,
) {
  return (
    <Argument
      id={index}
      key={index}
      share={share}
      expanded={expanded}
      expandable={
        indexEntry.speakers.length || indexEntry.subArgument !== undefined
      }
      handleChange={handleChange}
      showAttachments={attachmentsOpen}
      showLinks={linksOpen}
      links={indexEntry.argument?.links}
      label={indexEntry.argument.subject}
      minuteAttr={indexEntry.argument.time}
      minute={msToHhMm(indexEntry.argument.time)}
      attachments={indexEntry.argument.attachments}
      seekCb={(e: SyntheticEvent) => playerSeek(e, indexEntry.argument.time)}
    >
      {indexEntry.subArgument ? (
        <SubArgument
          share={share}
          parentSubject={indexEntry.argument.subject}
          showAttachments={attachmentsOpen}
          label={indexEntry.subArgument.subject}
          minuteAttr={indexEntry.subArgument?.time}
          attachments={indexEntry.subArgument.attachments}
          showLinks={linksOpen}
          links={indexEntry.subArgument?.links}
          minute={msToHhMm(indexEntry.subArgument?.time as number)}
          seekCb={(e: SyntheticEvent) =>
            playerSeek(e, indexEntry.subArgument?.time as number)
          }
        ></SubArgument>
      ) : (
        ''
      )}
      {indexEntry.speakers &&
        indexEntry.speakers.map((speaker: IndexSpeaker, index: number) => (
          <Speaker
            key={index}
            name={speaker.name}
            parentSubject={indexEntry.argument.subject}
            surname={speaker.surname}
            minuteAttr={speaker.time}
            minute={msToHhMm(speaker.time)}
            showLinks={linksOpen}
            links={speaker.links}
            attachments={speaker.attachments}
            showAttachments={attachmentsOpen}
            seekCb={(e: SyntheticEvent) => playerSeek(e, speaker.time)}
            share={share}
          ></Speaker>
        ))}
    </Argument>
  )
}

function timesFromEntries(entries: IndexEntry[]) {
  const refs = []
  for (let i = 0; i < entries.length; i++) {
    refs.push({
      mainContent: i,
      mainContentTime: entries[i].argument.time,
      minutes: entries[i].speakers.map((e: IndexSpeaker) => e.time),
    })
  }
  return refs
}

function closestMinuteAndContent(
  position: any,
  times: { mainContent: number; mainContentTime: number; minutes: number[] }[],
): Promise<{ mainContent: number; minute: number }> {
  return new Promise(resolve => {
    const closestMainContent: {
      mainContent: number
      mainContentTime: number
      minutes: number[]
    } | null = getClosestMainContent(position, times)
    if (closestMainContent) {
      if (closestMainContent.minutes.length > 0) {
        const closestMinute = getClosestMinute(
          position,
          closestMainContent.minutes,
        )
        if (closestMinute !== null) {
          return resolve({
            mainContent: closestMainContent.mainContent,
            minute: closestMinute,
          })
        }
      }
      return resolve({
        mainContent: closestMainContent.mainContent,
        minute: closestMainContent.mainContentTime,
      })
    }
    return resolve({ mainContent: 0, minute: times[0].mainContentTime })
  })
}

function getClosestMainContent(
  position: number,
  times: { mainContent: number; mainContentTime: number; minutes: number[] }[],
) {
  for (let i = 0; i < times.length; i++) {
    if (position > times[i].mainContentTime) {
      if (i === times.length - 1) return times[i]
      continue
    } else return times[i - 1]
  }
  return times[0]
}

function getClosestMinute(position: number, minutes: number[]) {
  if (minutes.length && position >= minutes.slice(-1)[0]) {
    return minutes.slice(-1)[0]
  }
  for (let i = 0; i < minutes.length; i++) {
    if (position > minutes[i]) continue
    else return minutes[i - 1]
  }
  return null
}

function scrollToEntry(minute: any) {
  const div = document.getElementById('index-scroll-container')
  const entry: any = document.querySelectorAll(`[data-minute="${minute}"]`)[0]
  if (div && entry) {
    highlightEntry(entry)
    scrollParentToChild(div, entry)
  }
}

function highlightEntry(entry: any) {
  document.querySelectorAll('[data-minute]').forEach((el: any) => {
    el.classList.remove('highlight')
  })
  entry.classList.add('highlight')
}

function mapIndexEntries(sessionEvents: SessionEvent[]): IndexEntry[] {
  if (sessionEvents?.length) {
    const entries: IndexEntry[] = []
    let currentArgument = null

    for (let i = 0; i < sessionEvents.length; i++) {
      switch (sessionEvents[i].type) {
        case SessionEventType.SPEAKER:
          {
            currentArgument?.speakers.push(
              new IndexSpeaker(
                sessionEvents[i].speaker?.name as string,
                sessionEvents[i].speaker?.surname as string,
                sessionEvents[i].time.relative,
                sessionEvents[i].attachments,
                sessionEvents[i].links,
              ),
            )
            if (i === sessionEvents.length - 1 && currentArgument) {
              entries.push(currentArgument)
            }
          }
          break
        case SessionEventType.GUEST:
          {
            currentArgument?.speakers.push(
              new IndexSpeaker(
                sessionEvents[i].speaker?.name as string,
                '',
                sessionEvents[i].time.relative,
                sessionEvents[i].attachments,
                sessionEvents[i].links,
              ),
            )
            if (i === sessionEvents.length - 1 && currentArgument) {
              entries.push(currentArgument)
            }
          }
          break
        case SessionEventType.SUB_ARGUMENT: {
          currentArgument
            ? (currentArgument.subArgument = {
                subject: sessionEvents[i].subject as string,
                time: sessionEvents[i].time.relative,
                attachments: sessionEvents[i].attachments,
                links: sessionEvents[i].links,
              })
            : null
          if (i === sessionEvents.length - 1 && currentArgument) {
            entries.push(currentArgument)
          }
          break
        }
        default: {
          if (currentArgument) {
            entries.push(currentArgument)
          }
          currentArgument = new IndexEntry(
            {
              subject: sessionEvents[i].subject as string,
              time: sessionEvents[i].time.relative,
              attachments: sessionEvents[i].attachments,
              links: sessionEvents[i].links,
            },
            [],
          )
          if (i === sessionEvents.length - 1) {
            entries.push(currentArgument)
          }
        }
      }
    }
    return entries
  }
  return []
}

function scrollParentToChild(parent: any, child: any) {
  // Where is the parent on page
  const parentRect = parent.getBoundingClientRect()
  // What can you see?
  // const parentViewableArea = {
  //   height: parent.clientHeight,
  //   width: parent.clientWidth,
  // }

  // Where is the child
  const childRect = child.getBoundingClientRect()
  // Is the child viewable?
  // const isViewable =
  //   childRect.top >= parentRect.top &&
  //   childRect.top <= parentRect.top + parentViewableArea.height

  // if you can't see the child try to scroll parent
  //if (!isViewable) {
  // scroll by offset relative to parent
  //parent.scrollTop = childRect.top + parent.scrollTop - parentRect.top
  //}
  parent.scrollTop = childRect.top + parent.scrollTop - parentRect.top
}

export enum SessionEventType {
  OPEN = 1,
  SUSPEND = 2,
  CLOSE = 3,
  ARGUMENT = 4,
  SUB_ARGUMENT = 5,
  SPEAKER = 6,
  RESUME = 7,
  GUEST = 8,
}
