import { Box, Container, makeStyles, Typography } from '@material-ui/core'
import React, { Fragment, useContext, useEffect } from 'react'
import Layout from '../components/Layout'
import { ArchiveSearchForm } from '../components/archive/search/ArchiveSearchForm'
import { ArchiveSearchResults } from '../components/archive/search/ArchiveSearchResults'
import { searchParams } from '../services/Archive/ArchiveService'
import { searchParamsToJSON } from '../shared/utils'
import { SearchCtx } from '../providers/context/SearchContext'
import { ArchiveLegislature } from '../components/archive/search/ArchiveLegislature'

const useStyles = makeStyles(theme => ({
  heroContent: {
    //backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 0, 2),
  },
  '@global': {
    body: {
      backgroundColor: 'white!important',
    },
    '.MuiButton-contained': {
      borderRadius: 15,
    },
  },
}))

export const Search: React.FC = () => {
  const classes = useStyles()
  const searchCtx = useContext(SearchCtx)
  const paramsJson: searchParams = searchParamsToJSON()

  const archiveView = () => {
    return (
      Object.keys(paramsJson).length === 0 ||
      (Object.keys(paramsJson).length === 1 && paramsJson.page)
    )
  }

  useEffect(() => {
    const anyValueSelected = Object.keys(paramsJson).length !== 0
    if (anyValueSelected) searchCtx.search(paramsJson)
  }, [])

  return (
    <Fragment>
      <Layout>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth='lg'>
            <Box mt={3} mb={3}>
              <Typography
                component='h2'
                variant='h4'
                style={{ fontWeight: 600, fontSize: '1.7rem' }}
                align='center'
                color='textPrimary'
                gutterBottom
              >
                ARCHIVIO
              </Typography>
            </Box>
            <ArchiveSearchForm></ArchiveSearchForm>
            {archiveView() ? (
              <ArchiveLegislature></ArchiveLegislature>
            ) : (
              <ArchiveSearchResults
                searchPars={paramsJson}
              ></ArchiveSearchResults>
            )}
          </Container>
        </div>
      </Layout>
    </Fragment>
  )
}
