import React, { ReactElement, ReactFragment, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

interface VodContextProps {
  children: React.ReactChildren | Element | ReactFragment
}

export interface VodContext {
  isVod: boolean
  isPlaying: boolean
  setIsPlaying: CallableFunction
  //playerSeek: boolean
  //setPlayerSeek: CallableFunction
  playerLoaded: boolean
  setPlayerLoaded: CallableFunction
  player: jwplayer.JWPlayer | null | any
  setPlayer: CallableFunction
  duration: number
  setReload: CallableFunction
  reload: any
  setDuration: CallableFunction
  ltEnabled: boolean
  setLtEnabled: CallableFunction
  ltCurrWordId: string
  setLtCurrWordId: CallableFunction
  playerSeek: any
}

export const VodCtx = React.createContext({} as VodContext)

export const VodContextProvider = (props: VodContextProps): ReactElement => {
  const [isPlaying, setIsPlaying] = useState(false)
  //const [playerSeek, setPlayerSeek] = useState(false)
  const [playerLoaded, setPlayerLoaded] = useState(false)
  const [player, setPlayer] = useState(null as any)
  const [duration, setDuration] = useState(0)
  const [ltEnabled, setLtEnabled] = useState(false)
  const [ltCurrWordId, setLtCurrWordId] = useState('')
  const [reload, setReload] = useState(new Date())

  const history = useHistory()
  const location = useLocation()

  const playerSeek = (time: number) => {
    history.push({
      pathname: location.pathname,
      search: `seek=${time}`,
    })
    return player !== null ? (player as jwplayer.JWPlayer).seek(time) : null
  }

  const vodContext = {
    isVod: true,
    playerLoaded,
    setPlayerLoaded,
    player,
    setPlayer,
    duration,
    setDuration,
    isPlaying,
    setIsPlaying,
    reload,
    setReload,
    //playerSeek,
    //setPlayerSeek,
    ltEnabled, // Live transcription
    setLtEnabled, // Live transcription
    ltCurrWordId, // Live transcription
    setLtCurrWordId, // Live transcription
    playerSeek,
  }
  return <VodCtx.Provider value={vodContext}>{props.children}</VodCtx.Provider>
}
